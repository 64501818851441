import React,{Fragment,useState,useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,checkIntNum,currentDateTime,getDateTimeFromISODT} from '../../lib/functions';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {APP_URL,API_URL} from '../../config.json';
import { useHistory } from "react-router-dom";
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {Multiselect} from 'multiselect-react-dropdown';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Modal } from 'react-responsive-modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import swal from 'sweetalert';
import { CardContent } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')




const ProductDamage = ({location,currentRoute,currentRouteSet,authInfo})=>{

    const classes = useStyles();
    const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
    const [mfgDt, mfgDtSet] = useState(currentDateTime);

    const history = useHistory();
   
    // States start 
      
       let [cart,cartSet] = useState([]);
       let [employees,employeesSet] = useState([]);
       let [customers,customersSet] = useState([]);
       let [products,productsSet] = useState([]);
       let [productPOCode, productPOCodeSet] = useState([]);
       let [productPOCart, setProductPOCart] = useState([]);
      
       let [is_tds, is_tds_set] = useState(false)
       let[distributors, distributorsSet] = useState([]);

       let [selectedEmployee,selectedEmployeeSet] = useState(null);
       let [selectedCustomer,selectedCustomerSet] = useState(null);
       let [selectedDistributor,selectedDistributorSet] = useState({customer_name:'', customer_pan_no:'', customer_gstin:'',customer_mobile_no:'', customer_state:''});
       let [selectedProduct,selectedProductSet] = useState(null);  
       let [selectedProductPOCode, selectedProductPOCodeSet] = useState({
        po_id:0,
        po_code: '',
        item_code: '',
        qty: '',
        sale_rate: '',
        debit_value: ''
      })
       let [saveAction,saveActionSet] = useState(false);

       let [customerPayAccs,customerPayAccsSet] = useState([])
       let [tds_value, tds_value_set] = useState('')

      let [cgst_amount, cgst_amount_set] = useState('')
      let [cgst_percent, cgst_percent_set] = useState('')
      let [igst_amount, igst_amount_set] = useState('')
      let [igst_percent, igst_percent_set] = useState('')
      let [sgst_amount, sgst_amount_set] = useState('')
      let [sgst_percent, sgst_percent_set] = useState('')
      let [rounded_off, rounded_off_set] = useState('')

       let [selectedCustomerPayMethod,selectedCustomerPayMethodSet] = useState({pay_method_id:'cash',pay_method:'Cash'},{pay_method_id:'bank',pay_method:'Bank'})
       let [selectedCustomerPayAcc,selectedCustomerPayAccSet] = useState(null)

       let [invoice,invoice_set] = useState('');
       let [emp_id,emp_id_set] = useState(0);
     
       let [created_isodt,created_isodt_set] = useState('');
       let [productUpdateIndex, setProductUpdateIndex] = useState(0)
       let [customer_name,customer_name_set] = useState('');
       let [customer_mobile_no,customer_mobile_no_set] = useState('');
       let [customer_address,customer_address_set] = useState('');
       let [customer_gstin, customer_gstin_set] = useState('');
       let [customer_pan_no, customer_pan_no_set] = useState('');

       let [distributor_name,distributor_name_set] = useState('');

       let [product_rate,product_rate_set] = useState(0);
       let [product_qty,product_qty_set] = useState('');
       let [product_total,product_total_set] = useState(0);
       let [prod_discount, prod_discount_set] = useState(0);
       let [updateCartBtn, setUpdateCartBtn] = useState(false)
       let [addCartBtn, setAddCartBtn] = useState(true)
       let [product_stock_qty,product_stock_qty_set] = useState('stock qty');
       let [product_unit,product_unit_set] = useState('unit');
       let [product_purchase_rate,product_purchase_rate_set] = useState('purchase rate');
       let [damageCode,damageCodeSet] = useState('');
       let [general_customer,general_customer_set] = useState(false);
       let [customer_type, customer_type_set] = useState('retail');
       let [note,note_set] = useState('');
       let [sub_total,sub_total_set] = useState(0);
     
       let [vat,vat_set] = useState(0);
       let [vat_percent,vat_percent_set] = useState(0);
       let [discount,discount_set] = useState(0);
       let [discount_percent,discount_percent_set] = useState(0);
       let [total_amount,total_amount_set] = useState(0);
       let [paid,paid_set] = useState(0);
      
       let [transport_cost,transport_cost_set] = useState(0);
       let [saleAction,saleActionSet] = useState('create');
       let [productDamageEntryId,productDamageEntryIdSet] = useState(0);
       let [customer_credit_limit,customer_credit_limit_set] = useState(0);
       let customerPayMethods = [{pay_method_id:'cash',pay_method:'cash'},{pay_method_id:'bank',pay_method:'bank'}]

    

    // State End
    
    // Methods  start
       useEffect(()=>{
        selectedCustomerSet(null)
        selectedDistributorSet({customer_name:'', customer_pan_no:'', customer_gstin:'',customer_mobile_no:'', customer_state:''})
        customer_name_set('')
        customer_address_set('')
        customer_mobile_no_set('')
        selectedProductSet(null)
        product_rate_set('')
        customer_gstin_set('')
        customer_pan_no_set('')
        cgst_amount_set('')
        cgst_percent_set('')
        igst_amount_set('')
        igst_percent_set('')
        sgst_amount_set('')
        sgst_percent_set('')
        prod_discount_set(0);
        // if(customer_type=='retail'){
        //   getCustomers('retail')
        // }
        // if(customer_type=='wholesale'){
        //   getCustomers('wholesale')
        // }
        
 
        let prods = products.map((prod)=>{
          // if(customer_type=='wholesale'){
          //   prod.prod_rate = prod.prod_whole_sale_rate
          // }
          // if(customer_type=='retail'){
          //   prod.prod_rate = prod.prod_sale_rate
          // }
          prod.prod_rate = prod.prod_sale_rate
          return prod;
        });

        productsSet(prods)

        
       },[customer_type])

      
       useEffect(()=>{
          currentRouteSet(pathSpliter(location.pathname,1));
          getEmployees()
          getCustomers()
          getProducts()
          getTranAccs()
          getDistributors()
          getDamageCode()

          if(pathSpliter(location.pathname,3) != undefined){
            saleActionSet('update');
            productDamageEntryIdSet(parseFloat(pathSpliter(location.pathname,3)));
            getProductDamageDetails();
          }
         
       },[])


       const getTranAccs = async ()=>{
        await axios.post(`${API_URL}/api/get-bank-accounts`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          customerPayAccsSet(res.data.message)
        })
       } 

       let getDamageCode = async ()=>{
        await axios.get(`${API_URL}/api/get-damage-code`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            invoice_set(res.data)
       })
      }

       // Calculate total 
     let  getProductDamageDetails = async ()=>{
         await axios.post(`${API_URL}/api/get-product-damage-with-details`,{reqPayload:{productDamageEntryId:parseFloat(pathSpliter(location.pathname,3))}},{headers:{'auth-token':authInfo.token}}).then(async(res)=>{
               let damage = res.data.message[0];
               let cart = res.data.message[0].details;
               setProductPOCart(cart);  
               console.log(damage,'Get all sales Update');
               
               cgst_amount_set(damage.cgst_amount)
               cgst_percent_set(damage.cgst_percent)
               igst_amount_set(damage.igst_amount)
               igst_percent_set(damage.igst_percent)
               sgst_amount_set(damage.sgst_amount)
               sgst_percent_set(damage.sgst_percent)
               invoice_set(damage.invoice);
               selectedEmployeeSet({employee_id:damage.emp_id,employee_name:damage.employee_name});
               handleDateChangeSet(damage.created_isodt);
               mfgDtSet(damage.mfgDt)
               selectedCustomerSet({customer_name:damage.customer_name,display_text:damage.customer_name,customer_id:damage.customer_id, customer_gstin: damage.customer_gstin, customer_pan_no: damage.customer_pan_no, customer_tax_value: damage.tds_value})
               selectedDistributorSet({customer_name:damage.distributor_name, customer_id:damage.distributor_id,customer_gstin: damage.distributor_gstin, customer_pan_no: damage.distributor_pan_no})
               customer_name_set(damage.customer_name)
               customer_mobile_no_set(damage.customer_mobile_no)
               customer_address_set(damage.customer_address)
               customer_credit_limit_set(damage.customer_credit_limit);
               customer_gstin_set(damage.customer_gstin);
               customer_pan_no_set(damage.customer_pan_no);
               rounded_off_set(damage.rounded_off)
               is_tds_set(damage.is_tds)
               debit_note_set(damage.debit_note)
               original_invoice_no_set(damage.original_invoice_no)
               terms_of_payment_set(damage.terms_of_payment)
               reference_no_set(damage.reference_no)
               discount_set(damage.discount_amount);
               discount_percent_set(damage.discount_percent);
               vat_set(damage.vat_amount);
               vat_percent_set(damage.vat_percent);
               total_amount_set(damage.total_amount);
               paid_set(damage.paid_amount);
              // due_set(sale.sale_due_amount);

              let newCart =  cart.map((ele)=>{
                 let product = {
                  prod_id: ele.prod_id,
                  prod_name: ele.prod_name,
                  prod_rate: ele.prod_rate,
                  prod_qty: ele.prod_qty,
                  prod_total: ele.prod_total_amount,
                  prod_discount: ele.prod_discount,
                  prod_po_code_id: ele.prod_po_code_id,
                 }
                 if(ele.prod_po_code_id == 1)
                  selectedProductPOCodeSet({
                   po_id:1,
                   po_code: ele.prod_po_1,
                   item_code: ele.prod_item_code_1,
                   qty: ele.prod_qty_name_1,
                   sale_rate: ele.prod_rate_1,
                   debit_value: ele.prod_debit_value_1
                 })

                 if(ele.prod_po_code_id == 2)
                  selectedProductPOCodeSet({
                   po_id:2,
                   po_code: ele.prod_po_2,
                   item_code: ele.prod_item_code_2,
                   qty: ele.prod_qty_name_2,
                   sale_rate: ele.prod_rate_2,
                   debit_value: ele.prod_debit_value_2
                 })
                 if(ele.prod_po_code_id == 3)
                  selectedProductPOCodeSet({
                   po_id:3,
                   po_code: ele.prod_po_3,
                   item_code: ele.prod_item_code_3,
                   qty: ele.prod_qty_name_3,
                   sale_rate: ele.prod_rate_3,
                   debit_value: ele.prod_debit_value_3
                 })
                 if(ele.prod_po_code_id == 4)
                  selectedProductPOCodeSet({
                   po_id:4,
                   po_code: ele.prod_po_4,
                   item_code: ele.prod_item_code_4,
                   qty: ele.prod_qty_name_4,
                   sale_rate: ele.prod_rate_4,
                   debit_value: ele.prod_debit_value_4
                 })
                 if(ele.prod_po_code_id == 5)
                  selectedProductPOCodeSet({
                   po_id:5,
                   po_code: ele.prod_po_5,
                   item_code: ele.prod_item_code_5,
                   qty: ele.prod_qty_name_5,
                   sale_rate: ele.prod_rate_5,
                   debit_value: ele.prod_debit_value_5
                 })
                 
                 return product;

               })   
               cartSet(newCart);

               selectedCustomerPayMethodSet({pay_method_id:damage.pay_method,pay_method:damage.pay_method})
               selectedCustomerPayAccSet({bank_display_name:damage.bank_display_name,bank_acc_id:damage.bank_id})

         });
    }
       
    useEffect(()=>{
        let subTotal = cart.reduce((prev,curr)=>{
          return prev+parseFloat(curr.prod_total);
        },0);
        sub_total_set(subTotal);
    },[cart,sub_total,vat,vat_percent,discount,discount_percent,paid,selectedCustomer,transport_cost]) 

    let employeeRef = useRef(null)
    let dateRef = useRef(null)
    let customerRef = useRef(null)
    let distributorRef = useRef(null)
    let productRef = useRef(null)
    let rateRef = useRef(null)
    let qtyRef = useRef(null)
    let addToCartRef = useRef(null)
    let vatRef = useRef(null)
    let vatPercentRef = useRef(null)
    let discountRef = useRef(null)
    let discountPercentRef = useRef(null)
    let paidRef = useRef(null)
    let dueRef = useRef(null)
    let saleRef = useRef(null)
    let cgstRefAmount = useRef(null)
    let cgstRefPercent = useRef(null)
    let sgstRefAmount = useRef(null)
    let sgstRefPercent = useRef(null)
    let igstRefAmount = useRef(null)
    let igstRefPercent = useRef(null)
    let transportRef = useRef(null)
    let [selectedMaterialPurchaseInvoice, selectedMaterialPurchaseInvoiceSet] = useState(null)
    let [debit_note, debit_note_set] = useState('')
    let [terms_of_payment, terms_of_payment_set] = useState('')
    let [reference_no, reference_no_set] = useState('')
    let [original_invoice_no, original_invoice_no_set] = useState('')


       useEffect(()=>{
        let total = product_rate*product_qty;
        let productTotal = (total - ((parseFloat(total) * parseFloat(prod_discount))/100) )
     
        product_total_set(productTotal);
       },[product_rate,product_qty, prod_discount])

      //  useEffect(()=>{
      //    if(selectedProduct!=null){
      //      axios.post(`${API_URL}/api/get-product-current-stock`,{product_id:selectedProduct.prod_id},{headers:{'auth-token':authInfo.token}}).then(res=>{
      //       product_stock_qty_set(res.data.message)
      //      })
      //    }else{
      //     product_stock_qty_set('Stock Qty')
      //    }
      //  },[selectedProduct])
      

       let getEmployees = async ()=>{
           await axios.post(`${API_URL}/api/get-employees`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          employeesSet( res.data.message)
          })
       }

       let getDistributors = async() => {
        await axios.post(`${API_URL}/api/get-customers`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          res.data.message.unshift({customer_id:0,customer_name:'General customer',display_text:'General customer',customer_mobile_no:'',customer_address:'',customer_type:'general'})
          distributorsSet(res.data.message)
         });
       }
       
       let getCustomers = async (type='retail')=>{
        await axios.post(`${API_URL}/api/get-customers`,{'select-type':'active','customer-type':type},{headers:{'auth-token':authInfo.token}}).then(res=>{
          res.data.message.unshift({customer_id:0,customer_name:'General customer',display_text:'General customer',customer_mobile_no:'',customer_address:'',customer_type:'general', customer_tax_value:0})
          customersSet(res.data.message)
         });
       }

       let getProducts = async ()=>{
        await axios.post(`${API_URL}/api/get-individual-products`,{'select-type':'active',
           prodId : selectedProduct != null ? selectedProduct.prod_id : null,
       },{headers:{'auth-token':authInfo.token}}).then(res=>[
           productsSet(res.data.message)
        ]);
       }


       const productToCart = ()=>{
        setAddCartBtn(true)
        setUpdateCartBtn(false)
           if(selectedProduct==null){
             swal({title:'Select Product',icon:'warning'}); return false;
           }
          //  if(parseFloat(product_rate)<=0 || product_rate==''){
          //   swal({title:'Sale Rate is Invalid.',icon:'warning'}); return false;
          //  }
          //  if(parseFloat(product_qty)<=0 || product_qty==''){
          //   swal({title:'Quantity is Invalid ',icon:'warning'}); return false;
          //  }
          //  if(parseFloat(selectedProduct.prod_id)!=521 && parseFloat(selectedProduct.prod_id)!=447  && (parseFloat(product_qty)>parseFloat(product_stock_qty) || parseFloat(product_stock_qty)<=0)){
          //   swal({title:'Stock Unavailable',icon:'warning'}); return false;
          //  }
           let checkProdExist = cart.findIndex((curr)=>{
               if(curr.prod_id==selectedProduct.prod_id){
                  return true;
                }
           });
          //  if(checkProdExist>-1){
          //   let hardCopy =  [...cart];

          //   hardCopy[checkProdExist].prod_qty = parseFloat( hardCopy[checkProdExist].prod_qty) + parseFloat(product_qty)
          //   hardCopy[checkProdExist].prod_sale_rate =  product_rate == ''? 0 : product_rate
          //   hardCopy[checkProdExist].prod_total =  hardCopy[checkProdExist].prod_qty * product_rate
           
          //   cartSet(hardCopy)
    
          //  }else{
            cartSet([...cart,{prod_id: selectedProduct.prod_id,
              prod_name: selectedProduct.prod_name,
              prod_rate: product_rate,prod_qty: product_qty,
              prod_discount: prod_discount,
              prod_total: product_total,
              prod_po_code_id: selectedProductPOCode.po_id,
            }]); 
          //  }
                      
            product_rate_set(0);
            product_qty_set('');
            product_total_set(0);
            selectedProductSet(null); 
            prod_discount_set('')
            productRef.current.focus();
           
       }

      //  let cartFromRemove = (index)=>{
      //   let hardCopy =  [...cart];
      //   hardCopy.splice(index, 1);
      //   cartSet(hardCopy)
      //  }
      const [selectedBillOptions, setSelectedBillOptions] = useState([]);
    
    
      const onSelect = (selectedList) => {
        setSelectedBillOptions(selectedList);
      };
    
      const onRemove = (selectedList) => {
        setSelectedBillOptions(selectedList);
      };
          
    const  cartFromRemove = (row,index)=>{
      let hardCopy =  [...cart];
          hardCopy.splice(index, 1);
          cartSet(hardCopy)
    }

    const updatePurchaseCart = async () => {
      if(selectedProduct==null){
        swal({title:'Select  purchase material',icon:'warning'});return false;
      }
      
      if(product_qty < 1){
        swal({title:'Quantity is invalid.',icon:'warning'});return false;
      }
      if(product_rate < 0){
        swal({title:'sale rate is invalid.',icon:'warning'});return false;
      }
      
     
      // let checkExit =   purchaseCart.findIndex((ele)=>{
      //     if(ele.prod_id==selectedProduct.prod_id){ 
      //       return true 
      //     }else{
      //       return false
      //     }
              
      // });
      // if(checkExit>-1){
      //   swal({title:"This purchase material already Exist.",icon:'warning'})
      //   return false;
      // }
      let product = {
        prod_id: selectedProduct.prod_id,
              prod_name: selectedProduct.prod_name,
              prod_rate: product_rate,prod_qty: product_qty,
              prod_discount: prod_discount,
              prod_total: product_total,
              prod_po_code_id: selectedProductPOCode.po_id,
      }
      let hardCopy =  [...cart];
          hardCopy.splice(productUpdateIndex, 1,product);
          cartSet(hardCopy)
      console.log(cartSet + "hey there purchaseCart");
      //purchaseCartSet([...purchaseCart,product]);
       
      selectedProductSet(null);
      setAddCartBtn(true)
      setUpdateCartBtn(false)
      //productRef.current.focus();
    }

    const  purchaseCartUpdate = (row,index)=>{
      setAddCartBtn(false)
      setUpdateCartBtn(true)
      setProductUpdateIndex(index)
      let hardCopy =  [...cart];
      const productData = cart[index];   
     console.log(productPOCart);
      selectedProductSet({prod_id:productData.prod_id, prod_name: productData.prod_name});
      prod_discount_set(productData.prod_discount)
      product_rate_set(productData.prod_rate)
      product_qty_set(productData.prod_qty)

      if(productPOCart && productPOCart[index])
      {let tmpObj1 = {
        po_id:1,
        po_code: productPOCart[index].prod_po_1,
        item_code: productPOCart[index].prod_item_code_1,
        qty: productPOCart[index].prod_qty_name_1,
        sale_rate: productPOCart[index].prod_rate_1,
        debit_value: productPOCart[index].prod_debit_value_1
      }
      let tmpObj2 = {
        po_id:2,
        po_code: productPOCart[index].prod_po_2,
        item_code: productPOCart[index].prod_item_code_2,
        qty: productPOCart[index].prod_qty_name_2,
        sale_rate: productPOCart[index].prod_rate_2,
        debit_value: productPOCart[index].prod_debit_value_2
      }
      let tmpObj3 = {
        po_id:3,
        po_code: productPOCart[index].prod_po_3,
        item_code: productPOCart[index].prod_item_code_3,
        qty: productPOCart[index].prod_qty_name_3,
        sale_rate: productPOCart[index].prod_rate_3,
        debit_value: productPOCart[index].prod_debit_value_3
      }
      let tmpObj4 = {
        po_id:4,
        po_code: productPOCart[index].prod_po_4,
        item_code: productPOCart[index].prod_item_code_4,
        qty: productPOCart[index].prod_qty_name_4,
        sale_rate: productPOCart[index].prod_rate_4,
        debit_value: productPOCart[index].prod_debit_value_4
      }
      let tmpObj5 = {
        po_id:5,
        po_code: productPOCart[index].prod_po_5,
        item_code: productPOCart[index].prod_item_code_5,
        qty: productPOCart[index].prod_qty_name_5,
        sale_rate: productPOCart[index].prod_rate_5,
        debit_value: productPOCart[index].prod_debit_value_5
      }
     
     
      productPOCodeSet([tmpObj1, tmpObj2, tmpObj3, tmpObj4, tmpObj5]);}

      if(productData.prod_po_code_id == 1 && productPOCart && productPOCart[index])
        selectedProductPOCodeSet({
         po_id:1,
         po_code: productPOCart[index].prod_po_1,
         item_code: productPOCart[index].prod_item_code_1,
         qty: productPOCart[index].prod_qty_name_1,
         sale_rate: productPOCart[index].prod_rate_1,
         debit_value: productPOCart[index].prod_debit_value_1
       });

       if(productData.prod_po_code_id == 2  && productPOCart && productPOCart[index])
        selectedProductPOCodeSet({
         po_id:2,
         po_code: productPOCart[index].prod_po_2,
         item_code: productPOCart[index].prod_item_code_2,
         qty: productPOCart[index].prod_qty_name_2,
         sale_rate: productPOCart[index].prod_rate_2,
         debit_value: productPOCart[index].prod_debit_value_2
       });

       if(productData.prod_po_code_id == 3  && productPOCart && productPOCart[index])
        selectedProductPOCodeSet({
         po_id:3,
         po_code: productPOCart[index].prod_po_3,
         item_code: productPOCart[index].prod_item_code_3,
         qty: productPOCart[index].prod_qty_name_3,
         sale_rate: productPOCart[index].prod_rate_3,
         debit_value: productPOCart[index].prod_debit_value_3
       });
       if(productData.prod_po_code_id == 4  && productPOCart && productPOCart[index])
        selectedProductPOCodeSet({
         po_id:4,
         po_code: productPOCart[index].prod_po_4,
         item_code: productPOCart[index].prod_item_code_4,
         qty: productPOCart[index].prod_qty_name_4,
         sale_rate: productPOCart[index].prod_rate_4,
         debit_value: productPOCart[index].prod_debit_value_4
       })
       if(productData.prod_po_code_id == 5  && productPOCart && productPOCart[index])
        selectedProductPOCodeSet({
         po_id:5,
         po_code: productPOCart[index].prod_po_5,
         item_code: productPOCart[index].prod_item_code_5,
         qty: productPOCart[index].prod_qty_name_5,
         sale_rate: productPOCart[index].prod_rate_5,
         debit_value: productPOCart[index].prod_debit_value_5
       })

      // setProduct({prod_purchase_rate:product.prod_purchase_rate,
      // prod_sale_rate:productData.prod_sale_rate,prod_id:productData.prod_id,prod_name:productData.prod_name, prod_total:productData.pur_total_amount, prod_qty: productData.prod_qty, prod_discount: productData.prod_discount})
      //console.log(product, productData)
      //purchaseCartSet(hardCopy)
      
    }

       useEffect(()=>{
        let total =  cart.reduce((prev,curr)=>{
          return prev+parseFloat(curr.prod_total);
      },0)
      sub_total_set(total)
      discount_set(parseFloat(total)*parseFloat(discount_percent)/100.0)
     // pur_discount_percent_set('')
      
      cgst_amount_set(parseFloat(total)*parseFloat(cgst_percent)/100.0)
     // cgst_percent_set('')
      igst_amount_set(parseFloat(total)*parseFloat(igst_percent)/100.0)
     // igst_percent_set('')
      sgst_amount_set(parseFloat(total)*parseFloat(sgst_percent)/100.0)
     // sgst_percent_set('')
      },[cart]) 
    

    const [purchaseRateShow,purchaseRateShowSet] = useState(true);

    let purchaseRateShowAction = ()=>{
        if(purchaseRateShow==true){
          purchaseRateShowSet(false)
        }else{
          purchaseRateShowSet(true)
        }
    }

    let saleSaveAction = async ()=>{
        if(selectedCustomer==null){
          swal({title:'Customer is required.',icon:'warning'});return false;
        }
        if(cart.length==0){
          swal({title:'Cart is Empty.',icon:'warning'});return false;
        }
    


        if(paid > 0 && selectedCustomerPayMethod == null){
          swal({
            title:'Select Payment  Receive Method',
            icon:'warning'
          });
          return false;
        }

        if(paid > 0 &&  selectedCustomerPayMethod != null && selectedCustomerPayMethod.pay_method_id=='bank' && selectedCustomerPayAcc == null ){
          swal({
            title:'Select  Payment Receive Account ',
            icon:'warning'
          });
          return false;
        }

        if(parseFloat(paid) <= 0 && selectedCustomerPayMethod !=null && selectedCustomerPayMethod.pay_method_id =='bank'){
          swal({
            title:'Paymentable Amount is 0 ',
            icon:'warning'
          });
          return false;
        }
        //console.log(selectedDistributor, 'SalesEntry Dis');
        
        let sale = {
            invoice,
            productDamageEntryId,
            emp_id: selectedEmployee != null? selectedEmployee.employee_id:0,
            created_isodt: selectedDate,
            customer_id: selectedCustomer.customer_id,
            customer_name: customer_name,
            customer_mobile_no: customer_mobile_no,
            customer_address: customer_address,
            customer_gstin: customer_gstin,
            customer_pan_no: customer_pan_no,
            distributor_id: selectedDistributor.customer_id,
            distributor_name: selectedDistributor.customer_name,
            transport_cost:transport_cost,
            sub_total,
            mfgDt,
            vat,
            vat_percent,
            discount,
            discount_percent,
            total_amount,
            paid,
            id:invoice,
            pay_method : selectedCustomerPayMethod !=null? selectedCustomerPayMethod.pay_method_id:'cash',
            bank_id :   selectedCustomerPayMethod==null || selectedCustomerPayMethod.pay_method_id =='cash' ? 0 :selectedCustomerPayAcc.bank_acc_id,
            note,
            cgst_amount,
            cgst_percent,
            igst_amount,
            igst_percent,
            sgst_amount,
            sgst_percent,
            rounded_off,
            selectedMaterialPurchaseInvoice: selectedMaterialPurchaseInvoice ? selectedMaterialPurchaseInvoice.m_purchase_id : 0,
            debit_note,
            original_invoice_no,
            terms_of_payment,
            reference_no,
            is_tds,
            tds_value:selectedCustomer.customer_tax_value ,
            tds_amt: parseFloat(parseFloat(selectedCustomer.customer_tax_value)*parseFloat(sub_total)/100.0).toFixed(2),
            total_amt_deduct_tds: parseFloat(parseFloat(sub_total) - parseFloat(parseFloat(selectedCustomer.customer_tax_value)*parseFloat(sub_total)/100.0)).toFixed(2) ,
        }

        // if(!general_customer && totalDue > customer_credit_limit && customer_credit_limit != 0){
        //   swal({
        //      title:`Credit Limit is  ${format(parseFloat(customer_credit_limit).toFixed(2))}. Total Due is ${format(parseFloat(totalDue).toFixed(2))}  `,
        //      icon:'warning'
        //   });
        //   return false;
        // }
       
        let url = `/api/save-product-damage`
        if(saleAction=='update'){
            url = `/api/update-product-damage`
        }
        saveActionSet(true)
         await axios.post(`${API_URL}${url}`,{cart,sale},{headers:{'auth-token':authInfo.token}}).then(res=>{
          console.log(res.data.message,'save state res');
          if(!res.data.error){
            console.log(res.data.message);
            swal({title:`${res.data.message.msg} successfully. Do you want to view invoice?`,icon:'success',buttons:true}).then(confirm=>{
              if(confirm){
                history.push(`/stock/product-damage-invoice/${res.data.message.productDamageEntryId}`)
                saveActionSet(false)
              }else{
                window.location.reload()
              }
            })
          }

          

         })
    }

    
   


  let handleVatInput = (e)=>{
    
    if(e.target.name=='cgst_amount'){  
      cgst_amount_set(e.target.value)
      let vatPercent = (100 * parseFloat(e.target.value))/parseFloat(sub_total-discount)
      cgst_percent_set(vatPercent.toFixed(2))
    }else if(e.target.name=='cgst_percent'){
      cgst_percent_set(e.target.value)
      let vatAmount = (parseFloat(sub_total-discount)*parseFloat(e.target.value))/100;
      cgst_amount_set(vatAmount.toFixed(2))
    }

    else if(e.target.name=='igst_amount'){  
      igst_amount_set(e.target.value)
      let vatPercent = (100 * parseFloat(e.target.value))/parseFloat(sub_total-discount)
      igst_percent_set(vatPercent.toFixed(2))
    }else if(e.target.name=='igst_percent'){
      igst_percent_set(e.target.value)
      let vatAmount = (parseFloat(sub_total-discount)*parseFloat(e.target.value))/100;
      igst_amount_set(vatAmount.toFixed(2))
    }

    else if(e.target.name=='sgst_amount'){  
      sgst_amount_set(e.target.value)
      let vatPercent = (100 * parseFloat(e.target.value))/parseFloat(sub_total-discount)
      sgst_percent_set(vatPercent.toFixed(2))
    }else if(e.target.name=='sgst_percent'){
      sgst_percent_set(e.target.value)
      let vatAmount = (parseFloat(sub_total-discount)*parseFloat(e.target.value))/100;
      sgst_amount_set(vatAmount.toFixed(2))
    }
    
    // let totalBill = (parseFloat(subTotal)-parseFloat(pur_discount_amount)+parseFloat(purchaseData.pur_transport_cost))+(parseFloat(cgst_amount) + parseFloat(sgst_amount) +parseFloat(igst_amount))
    // purTotalAmoSet(parseFloat(totalBill));
    // console.log(purTotalAmo);

  }

  // useEffect(()=>{
  //   console.log(productPOCode);

  // },[productPOCode])

  // useEffect(()=>{
  //   console.log(selectedProductPOCode);

  // },selectedProductPOCode)
  
  // useEffect(()=>{
  //   due_set(total_amount-paid)
  // },[paid, total_amount])
  
  useEffect(()=>{
     let totalBill = 0;
     let discountTotal = parseFloat(parseFloat(sub_total)-parseFloat(discount));
     let gstTotal = (parseFloat(parseFloat(cgst_amount) + parseFloat(sgst_amount) +parseFloat(igst_amount)));
     totalBill = discountTotal + gstTotal;
    total_amount_set(parseFloat(totalBill));
    let roundedOff = parseFloat(rounded_off);
    
    // if(rounded_off[0]=='-') {
    //   roundedOff = parseFloat(rounded_off.substring(1))*-1; 
    // }else{
    //   roundedOff = parseFloat(rounded_off.substring(0))
    // }
    total_amount_set(parseFloat(totalBill + roundedOff));
    
    if(general_customer){
        paid_set(total_amount)
        //due_set(0)
      }else{
        //due_set(total_amount-paid)
      }

    //due_set(total_amount)

    console.log(sub_total + " " + discountTotal + " " + gstTotal + " " + total_amount + " " + totalBill);
  },[sub_total, discount, cgst_amount, igst_amount, sgst_amount, rounded_off, cgst_percent, igst_percent, sgst_percent, paid,selectedCustomer,transport_cost]);
    
      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '2px'}}>Product Damage Entry</h4>
<Grid container spacing={3} > 
          <Grid item  xs={12} sm={2}>
          <TextField  autoComplete='off'  className={classes.fullWidth}  value={invoice} 
            label="Product Damage  Code" disabled name="product_damage_code" variant="outlined" size="small"  />
          </Grid> 
          
          <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
              onKeyDown={(e)=>{
                if(e.key=='Enter'){
                  dateRef.current.focus()
                }
              }}
              value={selectedEmployee}
              openOnFocus={true}
              autoHighlight={true}
              style={{width:'100%',height:'20px'}}
              loading={true}
              options={employees}
              onChange={(e,employee)=>{
               selectedEmployeeSet(employee)
              }}
              getOptionLabel={(option) => option.employee_name}
              renderInput={(params) => <TextField
                {...params} 
                label=" Employee" 
                variant="outlined"
                />}
                
          />
          </Grid>

          <Grid item xs={12} sm={2}>
          {/* <Link className={classes.plusLink} to="/sales/customers-manage" >+</Link>  */}
                <Autocomplete            
             autoHighlight={true}
              size="small"
              openOnFocus={true}
              style={{width:'100%'}}
              options={distributors}
              value={selectedDistributor}
              loading={true}
              onChange={async (e,distributor)=>{
               console.log(distributor);
                selectedDistributorSet(distributor)
              //  if(distributor==null){
              //   customer_name_set('')
              //    return false;
              //  }
              //  if(customer.customer_id==0){
              //   general_customer_set(true)
              //   previous_due_set(0)

              //  }else{

              //   general_customer_set(false)
      
              //  }
              //   customer_name_set(customer.customer_name)

               }}
             getOptionLabel={(option) => option.customer_name}
              renderInput={(params) => <TextField 
                {...params} 
                label="Consignee(to Ship)" 
                variant="outlined"
                
                />}
          />
                <TextField autoComplete="off" style={{display:general_customer==true?'':'none'}} label="Distributor name" variant="outlined" className={classes.inputField} value={selectedDistributor.customer_name} name="distributor_name" />
                <TextField autoComplete="off" disabled={general_customer==true?false:true} label="Distributor GSTIN" variant="outlined" className={classes.inputField} value={selectedDistributor.customer_gstin}  name="distributor_gst_no" />
                <TextField autoComplete="off" disabled={{display:general_customer==true?false:true}} label="Distributor PAN No" variant="outlined" className={classes.inputField} value={selectedDistributor.customer_pan_no}  name="distributor_pan_no" />
                <TextField autoComplete="off" disabled={general_customer==true?false:true} label="Distributor mobile" variant="outlined" className={classes.inputField} value={selectedDistributor.customer_mobile_no} name="distributor_mobile_no" />
                <TextField autoComplete="off" disabled={general_customer==true?false:true} label="Distributor address" variant="outlined" className={classes.inputField} value={selectedDistributor.customer_state}  name="distributor_address"/>

          </Grid>
          
          <Grid item  xs={12}  sm={2} > 
            <MuiPickersUtilsProvider  utils={DateFnsUtils}>
          
            <KeyboardDateTimePicker
            style={{ width: '100%',marginTop: '' }}
            value={selectedDate}
            inputRef={dateRef}
            onKeyDown={(e)=>{
              if(e.key=='Enter'){
                customerRef.current.focus()
              }
            }}
            onChange={handleDateChangeSet}
            name="date"
            label="date  time"
            format="yyyy/MM/dd hh:mm a"
               />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item  xs={12} sm={2}>
            <TextField label="Debit Note" variant="outlined" value={debit_note}  className={classes.fullWidth} onChange={(e)=>debit_note_set(e.target.value)}  name="debit_note" />
          </Grid>

          <Grid item  xs={12} sm={2}>
            <TextField label="Mode/Terms of Payment" variant="outlined" value={terms_of_payment}  className={classes.fullWidth} onChange={(e)=>terms_of_payment_set(e.target.value)}  name="terms_of_payment" />
          </Grid>

          <Grid item  xs={12} sm={2}>
            <TextField label="Reference No" variant="outlined" value={reference_no}  className={classes.fullWidth} onChange={(e)=>reference_no_set(e.target.value)}  name="reference_no" />
          </Grid>

          <Grid item  xs={12} sm={2}>
            <TextField label="Original Invoice No" variant="outlined" value={original_invoice_no}  className={classes.fullWidth} onChange={(e)=>original_invoice_no_set(e.target.value)}  name="original_invoice_no" />
          </Grid>

          <Grid item  xs={12}  sm={2} > 
            <MuiPickersUtilsProvider  utils={DateFnsUtils}>
          
            <KeyboardDateTimePicker
            style={{ width: '100%',marginTop: '' }}
            value={mfgDt}
            inputRef={dateRef}
            onKeyDown={(e)=>{
              if(e.key=='Enter'){
                customerRef.current.focus()
              }
            }}
            onChange={mfgDtSet}
            name="date"
            label="Mfg Dt."
            format="yyyy/MM/dd hh:mm a"
               />
            </MuiPickersUtilsProvider>
          </Grid>

        </Grid>
        
        </Paper>

    <Grid container spacing={3} >
        <Grid item xs={12} sm={8} >
          
          <Paper className={classes.paper}>
          <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '0px'}}>Customer   & Product Information</h4>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={5} className={classes.plusLinkDiv}>

                <Grid item xs={12} sm={5} ></Grid>

                <Link className={classes.plusLink} to="/sales/customers-manage" >+</Link> 
                <Autocomplete
                
             autoHighlight={true}
              size="small"
              openOnFocus={true}
              style={{width:'100%'}}
              options={customers}
              value={selectedCustomer}
              loading={true}
              onChange={async (e,customer)=>{
               
                selectedCustomerSet(customer)
               if(customer==null){
                customer_name_set('')
                customer_mobile_no_set('')
                customer_address_set('')
                customer_gstin_set('')
                customer_pan_no_set('')
                general_customer_set(false)
                 return false;
               }
               if(customer.customer_id==0){
                general_customer_set(true)
               

               }else{

                general_customer_set(false)
                 // Get customer Due
                await axios.post(`${API_URL}/api/get-customer-due`,{customerId:customer.customer_id},{headers:{'auth-token':authInfo.token}}).then(res=>{
                 
                  customer_credit_limit_set(customer.customer_credit_limit)
                })
               }
                customer_name_set(customer.customer_name)
                customer_mobile_no_set(customer.customer_mobile_no)
                customer_address_set(customer.customer_address) 
                customer_gstin_set(customer.customer_gstin) 
                customer_pan_no_set(customer.customer_pan_no)      
                
               }}
              getOptionLabel={(option) => option.display_text}
              renderInput={(params) => <TextField 
                inputRef={customerRef}
                onKeyDown={(e)=>{
                  if(e.key=='Enter'){
                    productRef.current.focus()
                  }
                }}
                {...params} 
                label="Choose a customer " 
                variant="outlined"
                
                />}
          />

                <TextField autoComplete="off" style={{display:general_customer==true?'':'none'}} label="customer name" variant="outlined" className={classes.inputField} value={customer_name} onChange={(e)=>customer_name_set(e.target.value)} name="customer_name" />
                <TextField autoComplete="off" disabled={general_customer==true?false:true} label="customer GSTIN" variant="outlined" className={classes.inputField} value={customer_gstin} onChange={(e)=>customer_gstin_set(e.target.value)} name="customer_mobile_no" />
                <TextField autoComplete="off" disabled={{display:general_customer==true?false:true}} label="customer PAN No" variant="outlined" className={classes.inputField} value={customer_pan_no} onChange={(e)=>customer_pan_no_set(e.target.value)} name="customer_name" />
                <TextField autoComplete="off" disabled={general_customer==true?false:true} label="customer mobile" variant="outlined" className={classes.inputField} value={customer_mobile_no} onChange={(e)=>customer_mobile_no_set(e.target.value)} name="customer_mobile_no" />
                <TextField autoComplete="off" disabled={general_customer==true?false:true} label="customer address" variant="outlined" className={classes.inputField} value={customer_address} onChange={(e)=>customer_address_set(e.target.value)} name="customer_address"/>

                </Grid>
                <Grid item xs={12} sm={5} className={classes.plusLinkDiv} style={{marginTop: '5px'}}>
                <Link className={classes.plusLink} to="/administration/products-manage" >+</Link> 
                <Autocomplete 
              openOnFocus={true}
              autoHighlight={true}
              style={{width:'100%',height:'20px'}}
              options={products}
              value={selectedProduct}
              loading={true}
              size="small"
              getOptionLabel={(option) => option.prod_name}
              onChange={(e,product)=>{
                qtyRef.current.focus();
                if(product==null){ 
                  product_rate_set(0);
                  product_qty_set('');
                  selectedProductSet(null)
                  return false
                }


                  product_rate_set(product.prod_rate);
                  selectedProductSet(product)
                  let tmpObj1 = {
                    po_id:1,
                    po_code: product.prod_po_1,
                    item_code: product.prod_item_code_1,
                    qty: product.prod_qty_name_1,
                    sale_rate: product.prod_rate_1,
                    debit_value: product.prod_debit_value_1
                  }
                  let tmpObj2 = {
                    po_id:2,
                    po_code: product.prod_po_2,
                    item_code: product.prod_item_code_2,
                    qty: product.prod_qty_name_2,
                    sale_rate: product.prod_rate_2,
                    debit_value: product.prod_debit_value_2
                  }
                  let tmpObj3 = {
                    po_id:3,
                    po_code: product.prod_po_3,
                    item_code: product.prod_item_code_3,
                    qty: product.prod_qty_name_3,
                    sale_rate: product.prod_rate_3,
                    debit_value: product.prod_debit_value_3
                  }
                  let tmpObj4 = {
                    po_id:4,
                    po_code: product.prod_po_4,
                    item_code: product.prod_item_code_4,
                    qty: product.prod_qty_name_4,
                    sale_rate: product.prod_rate_4,
                    debit_value: product.prod_debit_value_4
                  }
                  let tmpObj5 = {
                    po_id:5,
                    po_code: product.prod_po_5,
                    item_code: product.prod_item_code_5,
                    qty: product.prod_qty_name_5,
                    sale_rate: product.prod_rate_5,
                    debit_value: product.prod_debit_value_5
                  }
                 
                 
                  productPOCodeSet([tmpObj1, tmpObj2, tmpObj3, tmpObj4, tmpObj5]);
               }}
              renderInput={(params) => <TextField 
                inputRef={productRef}
                onKeyDown={(e)=>{
                  if(e.key=='Enter'){
                    rateRef.current.focus();
                  }
                }}
                {...params} 
                label="choose a product" 
                variant="outlined"
                
                />}
                
          />
        <br/>
        <Grid item sm={1} >
        </Grid>
        <Grid>
                     <Autocomplete 
                      openOnFocus={true}
                      autoHighlight={true}
                      style={{width:'100%',height:'20px'}}
                      options={productPOCode}
                      value={selectedProductPOCode}
                      loading={true}
                      size="small"
                      getOptionLabel={(option) => option.po_code}
                      onChange={(e,obj)=>{
                        // qtyRef.current.focus();
                        // if(product==null){ 
                        //   product_rate_set(0);
                        //   product_qty_set('');
                        //   selectedProductSet(null)
                        //   return false
                        // }


                        //   product_rate_set(product.prod_rate);
                        //   selectedProductSet(product)
                        selectedProductPOCodeSet(obj)
                        product_rate_set(obj.sale_rate)
                      }}
                      
                      renderInput={(params) => <TextField 
                        inputRef={productRef}
                        onKeyDown={(e)=>{
                          if(e.key=='Enter'){
                            rateRef.current.focus();
                          }
                        }}
                        {...params} 
                        label="choose a product PO code" 
                        variant="outlined"
                        
                        />}        
                    /> 
      </Grid>
      <br/>

      <Grid>
                     <Autocomplete 
                      openOnFocus={true}
                      autoHighlight={true}
                      style={{width:'100%',height:'20px'}}
                      options={productPOCode}
                      value={selectedProductPOCode}
                      loading={true}
                      size="small"
                      getOptionLabel={(option) => option.item_code}
                      onChange={(e,obj)=>{
                        // qtyRef.current.focus();
                        // if(product==null){ 
                        //   product_rate_set(0);
                        //   product_qty_set('');
                        //   selectedProductSet(null)
                        //   return false
                        // }


                        //   product_rate_set(product.prod_rate);
                        //   selectedProductSet(product)
                        selectedProductPOCodeSet(obj)
                        product_rate_set(obj.sale_rate)
                      }}
                      
                      renderInput={(params) => <TextField 
                        inputRef={productRef}
                        onKeyDown={(e)=>{
                          if(e.key=='Enter'){
                            rateRef.current.focus();
                          }
                        }}
                        {...params} 
                        label="choose a product Item code" 
                        variant="outlined"
                        
                        />}       
                    /> 
      </Grid>
      <br/>
               
                <Grid container >
                     {/* <Grid item xs={12} sm={6}>
                      <TextField type="text" style={{marginRight:'5px'}} label="Item Code" variant="outlined" className={classes.inputField} 
                      value={selectedProductPOCode.item_code} disabled={true}
                      onKeyDown={(e)=>{
                        if(e.key=='Enter'){
                          qtyRef.current.focus();
                        }
                      }} />
                      </Grid>
                     <Grid item sm={1} >
                     </Grid> */}
                      <Grid item xs={12} sm={6}>
                      <TextField type="number" style={{marginRight:'5px'}} label="sale rate" variant="outlined" className={classes.inputField} 
                      value={product_rate} onChange={(e)=>{product_rate_set(e.target.value)}} 
                      inputRef={rateRef}

                      onKeyDown={(e)=>{
                        if(e.key=='Enter'){
                          qtyRef.current.focus();
                        }
                      }} />
                      </Grid>
                      <Grid item sm={1} >
                      </Grid>
                      <Grid item xs={12} sm={5} >
                      <TextField type="number" label="quantity" variant="outlined" className={classes.inputField}
                      value={product_qty} onChange={(e)=>{product_qty_set(e.target.value)}} 
                      inputRef={qtyRef}
                      onKeyDown={(e)=>{
                        if(e.key=='Enter'){
                          addToCartRef.current.click();
                        }
                      }} />
                      </Grid>

                      <Grid container >
                      <Grid item xs={12} sm={6}>
                      <TextField style={{marginRight:'5px'}} 
                      type="number"
                    
                      value={prod_discount}
                     
                      label="Discount(in %)"  size="small"  onChange={(e) => {
                        prod_discount_set(e.target.value)
                      }} name="prod_discount"  variant="outlined" className={classes.inputField} />
                      </Grid>
                    
                      
                     
                </Grid>

                      <Grid item xs={12} sm={12} >
                      <TextField label="total" variant="outlined" value={product_total} onChange={(e)=>{product_total_set(e.target.value)}} disabled={true} className={classes.inputField} />
                      </Grid>
             

                </Grid>


                <Grid container >
                      
                      <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'14px'}} 
                            
                            buttonRef={addToCartRef}
                            disabled={!addCartBtn}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={()=>productToCart()}
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                        >
                       Add  To Cart
                      </Button>
                      <Button 
                      buttonRef={addToCartRef}
                      onClick={updatePurchaseCart}
                      disabled={!updateCartBtn}
                      style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                        >
                        Update Cart 
                      </Button>
                </Grid>

              

                
                </Grid>

                {/* <Grid item xs={12} sm={2}> 
              <strong style={{color:selectedProduct!=null?(parseFloat(product_stock_qty)>0?'green':'red'):'green',fontSize:'12px'}} className={"stock-info-msg"}>{selectedProduct!=null ?(parseFloat(product_stock_qty)>0?'Stock Available':'Stock Unavailable'):'Stock Status'}</strong> 
                      <div className={"stock-info"}>
                           <strong style={{color: selectedProduct!=null?(parseFloat(product_stock_qty)>0?'green':'red'):'green',fontSize:'12px'}}>{product_stock_qty}</strong>
                           <p style={{color: '#005600',fontSize:'12px'}}>{selectedProduct!=null?selectedProduct.prod_unit_name:'Unit Name'}</p>
                      </div>
                      <div  className={"stock-info-purchaserate"} onClick={()=>{purchaseRateShowAction()}}> 
                         <input  type={purchaseRateShow?"password":"text"} value={selectedProduct!=null?parseFloat(selectedProduct.prod_purchase_rate).toFixed(2):'Purchase Rate'} disabled></input>
                      </div>
                </Grid> */}
                </Grid>
          </Paper>


          <Grid container>
            
            <Grid item xs={12}>
                  <Paper className={classes.paper} style={{marginTop:'5px'}}>
                  <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '3px'}}>Sales Cart</h4>
                  <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
              <TableRow>
              <TableCell>SL</TableCell>
              <TableCell align="left">Product</TableCell>
               <TableCell align="left">PO</TableCell>
                <TableCell align="left">Item Code</TableCell>
              <TableCell align="center">Sale Rate</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">Discount(%)</TableCell>
              <TableCell align="right">Total Amount</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right" >Remove</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
        {
            cart.map((product,sl)=>(
              <TableRow key={parseFloat(sl)+1}>
              <TableCell>{parseFloat(sl)+1}</TableCell>
              <TableCell align="left">{product.prod_name}</TableCell>
              <TableCell align="left">{selectedProductPOCode.po_code}</TableCell>
              <TableCell align="left">{selectedProductPOCode.item_code}</TableCell>
              <TableCell align="center">{product.prod_rate}</TableCell>
              <TableCell align="center">{product.prod_qty}</TableCell>
              <TableCell align="center">{product.prod_discount}</TableCell>
              <TableCell align="right">{format(parseFloat(product.prod_total).toFixed(2))}</TableCell>
              <TableCell align="right" >
              <EditIcon style={{cursor:'pointer',color:'green',marginLeft:'2px'}} onClick={()=>{purchaseCartUpdate(product,sl)}}></EditIcon></TableCell>
              <TableCell align="right" >
              <RemoveCircleIcon style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} onClick={()=>{cartFromRemove(sl)}}></ RemoveCircleIcon></TableCell>
            </TableRow>
            ))
          }

{cart.length!=0?
        <TableRow >
          <TableCell colSpan={3} align="right" style={{fontWeight:'bold'}} >Total :</TableCell>
          <TableCell align="center" style={{fontWeight:'bold'}}>{cart.reduce((prev,curr)=>{
                         return prev +parseFloat(curr.prod_qty)
                       },0).toFixed(2)}</TableCell>
          <TableCell align="right" style={{fontWeight:'bold'}} > {format(parseFloat(sub_total).toFixed(2))}</TableCell>
         <TableCell colSpan={1}></TableCell>
        </TableRow>
        :''}
        </TableBody>
      </Table>
    </TableContainer>



    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
      {cart.length!=0?
          <TableRow > 
             <TableCell style={{width: '325px'}}><TextareaAutosize onChange={(e)=>{note_set(e.target.value)}}  name="note" style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Sale Note..." rowsMin={3} placeholder="Sales Note..." value={note} />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
          :''}
      </Table>
    </TableContainer>
                  </Paper>
            </Grid>
          
          </Grid>
        
        </Grid>

        <Grid item xs={12} sm={4} >
        
        <Paper className={classes.paper}>
        <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
  marginBottom: '3px'}}>Amount Details</h4>
        <Grid container style={{paddingRight:'1px'}}>
                    <Grid item xs={12} sm={5}>
                    <TextField type="number" style={{marginRight:'5px'}} label="Subtotal"  
                       size="small"  disabled value={sub_total} name="sub_total" onChange={(e)=>sub_total_set(e.target.value)} 
                      variant="outlined" className={classes.inputField} 
                      />
                    </Grid>
                    <Grid item xs={0} sm={1}>
                    </Grid>


                  

                    <Grid item xs={12} sm={6}>
                    <TextField type="number" style={{marginRight:'5px'}}  size="small"  label="Transport Cost"  variant="outlined" className={classes.inputField} 
                
                value={transport_cost} name="transport_cost" onChange={(e)=>transport_cost_set(e.target.value)}
                    />
                    </Grid>


                   
                    
                   
                   
        </Grid>

        {/* <Grid container>
                    <Grid item xs={12} sm={5} >
                    <TextField type="number" label="GST" 
                    value={vat} name="vat" onChange={(e)=>{
                      
                      let vatPercent = parseFloat((100 * e.target.value)/sub_total).toFixed(2)
                      vat_percent_set(vatPercent)

                      vat_set(e.target.value)}} 
                    variant="outlined"  size="small"   className={classes.inputField} 
                    inputRef={vatRef}
                    onKeyDown={(e)=>{
                      if(e.key=='Enter'){
                        vatPercentRef.current.focus()
                      }
                    }}
                   
                   />
                    </Grid>
                    <Grid item xs={0} sm={1}>
                    </Grid>

                    <Grid item xs={12} sm={6} >

                    <TextField type="number" label="GST (%)" variant="outlined"  size="small"   className={classes.inputField} 
                    value={vat_percent} name="vat_percent" onChange={(e)=>{

                      let vatAmount = (sub_total*(e.target.value/100));
                      vat_set(parseFloat(vatAmount).toFixed(0))

                      vat_percent_set(e.target.value)}} 
                    inputRef={vatPercentRef}
                    onKeyDown={(e)=>{
                      if(e.key=='Enter'){
                        discountRef.current.focus()
                      }
                    }}
                    />
                    </Grid>

        </Grid> */}

        <Grid container style={{paddingRight:'1px'}}>
                    
                    <Grid item xs={0} sm={1}>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                    <TextField type="number" label="Discount (%)"  size="small"  variant="outlined" className={classes.inputField} 
                     value={discount_percent} name="discount_percent" onChange={(e)=>{
                      let discountAmount = (sub_total*e.target.value)/100;
                        discount_set(discountAmount) 
                      discount_percent_set(e.target.value)
                    }}
                     inputRef={discountPercentRef}
                     onKeyDown={(e)=>{
                      if(e.key=='Enter'){
                        discountRef.current.focus()
                      }
                    }}
                     />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                    <TextField type="number" style={{marginRight:'5px'}}  size="small"  label="Discount(Amt)" variant="outlined" className={classes.inputField} 
                    value={discount} name="discount" onChange={(e)=>{
                      let discountPercent = ((100 * e.target.value)/sub_total)
                       discount_percent_set(discountPercent)

                      discount_set(e.target.value)}} 
                    inputRef={discountRef}
                    onKeyDown={(e)=>{
                      if(e.key=='Enter'){
                        cgstRefPercent.current.focus()
                      }
                    }} 
                    /> 
                    </Grid>    
        </Grid>
        <Grid container>
                      <Grid item xs={12} sm={5} >
                      <TextField type="number" label="CGST (%)" variant="outlined"  size="small"   className={classes.inputField} 
                      value={cgst_percent}
                      inputRef={cgstRefPercent} 
                      name="cgst_percent"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?cgstRefAmount.current.focus():false} />
                      </Grid>
                      <Grid item xs={0} sm={1}>
                      </Grid>

                      <Grid item xs={12} sm={6} >
                      <TextField type="number" label="CGST" variant="outlined"  size="small"   className={classes.inputField} 
                      value={cgst_amount}
                      inputRef={cgstRefAmount} 
                      name="cgst_amount"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?sgstRefPercent.current.focus():false} />
                      </Grid>

          </Grid>
          <Grid container>
                      <Grid item xs={12} sm={5} >
                      <TextField type="number" label="SGST (%)" variant="outlined"  size="small"   className={classes.inputField} 
                      value={sgst_percent}
                      inputRef={sgstRefPercent} 
                      name="sgst_percent"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?sgstRefAmount.current.focus():false} />
                      </Grid>
                      <Grid item xs={0} sm={1}>
                      </Grid>

                      <Grid item xs={12} sm={6} >
                      <TextField type="number" label="SGST" variant="outlined"  size="small"   className={classes.inputField} 
                      value={sgst_amount}
                      inputRef={sgstRefAmount} 
                      name="sgst_amount"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?igstRefPercent.current.focus():false} />
                      </Grid>

          </Grid>
          <Grid container>
                      <Grid item xs={12} sm={5} >
                      <TextField type="number" label="IGST (%)" variant="outlined"  size="small"   className={classes.inputField} 
                      value={igst_percent}
                      inputRef={igstRefPercent} 
                      name="igst_percent"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?igstRefAmount.current.focus():false} />
                      </Grid>
                      <Grid item xs={0} sm={1}>
                      </Grid>

                      <Grid item xs={12} sm={6} >
                      <TextField type="number" label="IGST" variant="outlined"  size="small"   className={classes.inputField} 
                      value={igst_amount}
                      inputRef={igstRefAmount} 
                      name="igst_amount"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?transportRef.current.focus():false} />
                      </Grid>

          </Grid>

         
          <Grid>
                      <Grid item xs={12} sm={6} >
                      <TextField type="text"  label="Rounded Off"  size="small"  variant="outlined" className={classes.inputField} 
                      onKeyDown={(e)=>e.key==='Enter'?paidRef.current.focus():false}
                      value={rounded_off}
                      onChange = {(e) => rounded_off_set(e.target.value)}
                      name="rounded_off"
                      inputRef={transportRef} />
                      </Grid>    
                        
          </Grid>
        <Grid container style={{paddingRight:'1px'}}>

        <Grid item xs={12} sm={5}>
                    <TextField type="number" style={{marginRight:'5px'}}  size="small"  label="Total" disabled variant="outlined" className={classes.inputField}  
                value={total_amount} name="total_amount" onChange={(e)=>total_amount_set(e.target.value)}
                    />
                    </Grid>
       
                    <Grid item xs={0} sm={1}>
                    </Grid>
                    
                    {/* <Grid item xs={12} sm={6}>
                    <TextField type="number" style={{marginRight:'5px'}}  size="small" 
                    value={previous_due} name="previous_due" onChange={(e)=>previous_due_set(e.target.value)} 
                    label="Previous due" disabled variant="outlined" className={classes.inputField} 
                 
                   />
                    </Grid> */}
                  
                   
                      
        </Grid>


        <Grid container style={{paddingRight:'1px'}}>
        <Grid item xs={12} sm={5} >
                    <TextField type="number" label="Paid" variant="outlined"  size="small"  className={classes.inputField} 
                    value={paid} name="paid" onChange={(e)=>{
                      selectedCustomerPayMethodSet(null)
                      paid_set(e.target.value)}}
                    inputRef={paidRef}
                    onKeyDown={(e)=>{

                      
                    }}
                    />
                    </Grid> 

                    <Grid item xs={0} sm={1}>
                    </Grid>
                       
                    {
                      paid > 0 ?(
                        <Grid item xs={12} sm={5}  className={classes.plusLinkDiv} style={{marginTop:'15px',marginBottom:'10px'}}>
                        <Autocomplete
                        style={{ width: '100%' }}
                        options={customerPayMethods}
                        size="small"
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight={true}
                        openOnFocus={true}
                        getOptionLabel={(option) => option.pay_method}
                        value={selectedCustomerPayMethod}  
                        onChange={(event,selectedObj)=>{
                 
                          selectedCustomerPayMethodSet(selectedObj) 
                          if(selectedObj != null && selectedObj.pay_method_id =='cash'){
                            selectedCustomerPayAccSet(null)
                          }
                          
                        }}
                        loading={customerPayMethods.length==0?true:false}
                        renderInput={(params) => (
                            <TextField
                            //inputRef={customerPayMethodRef}
                            // onKeyDown={event => {
                            //     if (event.key === "Enter") {
                            //       customerPayAccRef.current.focus() 
                            //     }
                            //   }}
                            {...params}
                            label="Payment Method"
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {customerPayMethods.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment} */}
                                  </React.Fragment>
                                ),
                            }}
                            />
                        )}
                        /> 
                    </Grid>
                      ):''
                    }
                    

              <Grid item xs={12} sm={1}>

              </Grid>


              {paid > 0 ?(
                  selectedCustomerPayMethod != null && selectedCustomerPayMethod.pay_method =='bank'?(
                    <Grid item xs={12} sm={6}   className={classes.plusLinkDiv} style={{display:(selectedCustomerPayMethod!=null && selectedCustomerPayMethod.pay_method_id=='bank')?'block':'none',marginTop:'15px',marginBottom:'10px'}}>
                    <Autocomplete
                    style={{ width: '100%' }} 
                    options={customerPayAccs} 
                    size="small"
                    classes={{
                        option: classes.option
                    }}
                    autoHighlight={true}
                    openOnFocus={true}
                    getOptionLabel={(option) =>option.bank_display_name}
                    value={selectedCustomerPayAcc}  
                    onChange={(event,selectedObj)=>{
                      selectedCustomerPayAccSet(selectedObj)  
                     
                    }}
                    loading={customerPayAccs.length==0?true:false}
                    renderInput={(params) => (
                        <TextField
                        //inputRef={customerPayAccRef}
                        // onKeyDown={event => {
                        //     if (event.key === "Enter") {
                        //         customerPayMethodRef.current.focus() 
                        //     }
                        //   }}
                        {...params}
                        label="Bank account"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            endAdornment: (
                              <React.Fragment>
                                {/* {customerPayAccs.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment} */}
                              </React.Fragment>
                            )
                        }}
                        />
                    )}
                    /> 
                </Grid>
                  ):''
              ):''
                
              }

          {/* tds/tcs selection starts */}
          <Grid item xs={12} sm={3}> 
            <FormControlLabel
                control={
                  <Checkbox
                  
                    checked={is_tds}
                    onChange={(event)=>{
                      is_tds_set(event.target.checked);
                      
                    }}
                    name="checkedI"
                  />
                }
                label="TDS"
                style={{color: '#0f7e77'}}
            />
            </Grid>
            <Grid container style={{paddingRight:'1px'}}>
             {
                is_tds && selectedCustomer && (
                   <>
                       <Grid item xs={12} sm={5} >
                          <TextField type="number" label="TDS/TCS" variant="outlined"  size="small"  className={classes.inputField} 
                          disabled
                          value={selectedCustomer.customer_tax_value} name="TDS/TCS"
                          /> 
                       </Grid> 
                       <Grid item xs={0} sm={1}>
                       </Grid>
                       <Grid item xs={12} sm={5} >
                          <TextField type="number" label="TDS/TCS Amt" variant="outlined"  size="small"  className={classes.inputField} 
                          disabled
                          value={parseFloat(parseFloat(selectedCustomer.customer_tax_value)*parseFloat(sub_total)/100.0).toFixed(2)} name="TDS/TCS Amt"
                          /> 
                       </Grid>
                       <Grid item xs={0} sm={1}>
                       </Grid>
                       <Grid item xs={12} sm={5} >
                          <TextField type="number" label="Total" variant="outlined"  size="small"  className={classes.inputField} 
                          disabled
                          value={parseFloat(parseFloat(sub_total) - (parseFloat(selectedCustomer.customer_tax_value)*parseFloat(sub_total)/100.0)).toFixed(2)} name="Total"
                          /> 
                       </Grid>  
                   </>
                )
             } 
            </Grid>
        </Grid>
      

        <Grid container style={{paddingRight:'1px'}}>
                    <Grid item xs={12} sm={6}>
                    <Button style={{marginTop: '5px',fontSize:'18px',float:'left'}} 
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled={saveAction?true:false}
                          buttonRef={saleRef}
                          onClick={()=>{saleSaveAction()}}
                          className={classes.button}
                          startIcon={<SaveIcon/>}
                      >
                     Save
                    </Button>
                    </Grid>
                
                    <Grid item xs={12} sm={6} >
                    <Button 
                    style={{marginTop: '5px',fontSize:'10px',float:'right'}} 
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={()=>window.location.reload()}
                          className={classes.button}
                          startIcon={<SaveIcon/>}
                      >
                     New Sale
                    </Button>
                    </Grid>    
        </Grid>


        </Paper>
        </Grid>

        
      </Grid>
          </div>
      )
}


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(ProductDamage);