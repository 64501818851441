import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './invoice.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const PDInvoiceBody = ({damageData})=>{
    const classes = useStyles();
    // Invoice body
  
        /// end 

        // console.log(sale)
         let [damage,damageSet] = useState([])
          let [productDetails,productDetailsSet] = useState([])

        useEffect(()=>{
            damageSet(damageData)
            console.log(damageData)
            if(damageData && damageData.details!=undefined){
              productDetailsSet(damageData.details)
            }
            },[damageData])


      return(
          <>
        <table className={'invoice-table'}> 
          <thead>
              
                <td  style={{textAlign:'center'}} rowSpan={7}>SL</td>
                <td style={{textAlign:'center'}}>Description of Goods</td>
                <td >Qty</td>
                <td >Rate</td>
                <td >per</td>
                <td>Disc.%</td>
                <td >Amount</td>
             
          </thead>
          <tbody>
        

            {
              productDetails.map((detail,ind) =>(
                  <>
                   <tr>
                       <td rowSpan={8} style={{textAlign:'center'}}>{ind+parseFloat(1)}</td>
                        
                       <td style={{textAlign:'center', border:'none'}}>  {detail.prod_name} </td>
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>
                           {detail.prod_qty} {detail.prod_unit_name}
                       </td>
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>
                          {format(parseFloat(detail.prod_rate).toFixed(2))} 
                       </td>
                       <td style={{textAlign:'center', border:'none'}}>{detail.prod_unit_name}</td>
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>
                          {format(parseFloat(detail.prod_discount).toFixed(2))} 
                       </td>
                       <td  style={{textAlign:'center', borderBottomStyle: 'none',borderTopStyle: 'none'}}>
                           {format(parseFloat(detail.prod_total_amount).toFixed(2))}
                       </td>
                   </tr>
                  
                  <tr>
                     
                     <td style={{textAlign:'center', border:'none'}}>
                       Batch: {damage.original_invoice_no}
                     </td>
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                   </tr>
                 
                   <tr>
                      
                      <td style={{textAlign:'center', border:'none'}}>
                        Mfg. Dt. {damage.mfgDt}
                       </td>
                       <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                       <td style={{color:"#FFF",borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>  
                       <td style={{color:"#FFF",borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                       <td style={{color:"#FFF",borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                       <td style={{color:"#FFF",borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                  </tr>
                  
                  <tr>
                      <td style={{textAlign:'center', border:'none'}}>
                        {damage.note != null ? damage.note : "VECV Rejection"}
                      </td>
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                  </tr>
               
                  <tr>
                       <td style={{textAlign:'right', border:'none'}}>SGST {format(parseFloat(damage.sgst_percent).toFixed(2))} %</td>
                       <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                       <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(damage.sgst_amount).toFixed(2))}</td>
                  </tr>
                 
                   <tr>
                        <td style={{textAlign:'right', border:'none'}}>CGST {format(parseFloat(damage.cgst_percent).toFixed(2))} %</td>
                        <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                        <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                        <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>  
                        <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                        <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(damage.cgst_amount).toFixed(2))}</td>
                   </tr>
                
                   <tr>
                      <td style={{textAlign:'right', border:'none'}}>IGST {format(parseFloat(damage.igst_percent).toFixed(2))}</td>
                      <td style={{color:"#FFF",  borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{color:"#FFF",  borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                      <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(damage.igst_amount).toFixed(2))}</td>
                   </tr>

                   <tr>
                      <td style={{textAlign:'right', border:'none'}}>Rounded Off </td>
                      <td style={{color:"#FFF",  borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{color:"#FFF",  borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                      <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(damage.rounded_off).toFixed(2))} </td>
                   </tr>

                  </>
               ))
            } 

                  <tr>
                      <td></td>
                       <td  style={{textAlign:'right'}}>Total</td>
                       <td  >{productDetails.reduce((prev,curr)=>{
                         return prev +parseFloat(curr.prod_qty)
                       },0).toFixed(2)}</td>
                       <td></td>
                       <td  style={{textAlign:'left'}}></td>
                       <td  style={{textAlign:'center'}}>{productDetails.reduce((prev,curr)=>{
                         return prev +parseFloat(curr.prod_discount)
                       },0).toFixed(2)}</td>
                       <td  style={{textAlign:'center'}}>Rs. {format(parseFloat(damage.total_amount).toFixed(2))}</td>
                   </tr>
              

              
        </tbody>
        </table>
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default PDInvoiceBody;