import React,{useState,useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,dateTimeFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import _ from 'lodash';
import PrintIcon from '@material-ui/icons/Print';
import Select from 'react-select';
import '../commons/commons.css'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import swal from 'sweetalert';
import  InstitutionProfile from '../commons/institution_profile'
import ReactToPrint from "react-to-print";
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const DamageList = ({location,currentRoute,currentRouteSet,authInfo})=>{
  const classes = useStyles();
  useEffect(()=>{
      currentRouteSet(pathSpliter(location.pathname,1));
  },[]);
  let [report,reportSet] = useState([]);
  let [print,printSet] = useState(false);
  let [reportLoading,reportLoadingSet] = useState(false);


  let [byDateTime,byDateTimeSet] = useState({
        fromDate: currentDateStartTime(),
        toDate:currentDateEndTime()
  })

  let customerRef = useRef(null);
  let fromDateRef = useRef(null);
  let reportRef = useRef(null);



 useEffect(()=>{
    getDamages()
 },[])
 
 let getDamages = async()=>{
    await axios.post(`${API_URL}/api/get-damages`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        reportSet(res.data)
        printSet(true)
      })
 }
 

  let getSearchResult = async ()=>{
      let payload = {
          fromDate: byDateTime.fromDate,
          toDate: byDateTime.toDate
      }

     
      reportLoadingSet(true)
      await axios.post(`${API_URL}/api/get-damages`,{...payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
        reportSet(res.data)
        printSet(true)
        reportLoadingSet(false)
      })

  }

  let deleteRecord = async (productDamageEntryId)=>{
    swal({
       title:'Are you sure delete this?',
       icon:'warning',
       buttons:true
    }).then(async (confirm)=>{
      if(confirm){
        await axios.post(`${API_URL}/api/product-damage-delete`,{productDamageEntryId},{headers:{'auth-token':authInfo.token}}).then(res=>{
          if(!res.data.error){
           swal({
             title:res.data.message,
             icon:'success'
          })
          getSearchResult()
          } 
     })
      }else{
        return false
      }
      
    })
    
  }
let restoreRecord = async (productDamageEntryId)=>{
  swal({
     title:'Are you sure restore this?',
     icon:'warning',
     buttons:true
  }).then(async (confirm)=>{
    if(confirm){
      await axios.post(`${API_URL}/api/product-damage-restore`,{productDamageEntryId},{headers:{'auth-token':authInfo.token}}).then(res=>{
        if(!res.data.error){
         swal({
           title:res.data.message,
           icon:'success'
        })
        getSearchResult()
        } 
   })
    }else{
      return false
    }
    
  })
  
}


  let ReportDom = React.forwardRef((props,ref)=>{
      return(<div ref={ref}>
        <InstitutionProfile />
               {
     report.length>0?(
      <Paper className={classes.paper} style={{marginTop:'8px',marginBottom:'5px'}} >
  

      <TableContainer >
        <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
          <TableHead>
            <TableRow> 
              <TableCell align="left" style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left" style={{width:'10%'}}>Entry Date-Time </TableCell>
              <TableCell align="left" style={{width:'10%'}}>Damage Code</TableCell>
            
              <TableCell align="left" style={{width:'15%'}}>Product Name</TableCell>
              <TableCell align="left" style={{width:'15%'}}>Damage Rate</TableCell>
              <TableCell align="right" style={{width:'15%'}}>Damage Quantity </TableCell>
              <TableCell align="right" style={{width:'15%'}}>Damage Total </TableCell>
              <TableCell align="right" className="print-no">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>


        
              
              {
                report.map((row,sl)=>(
                    <TableRow  key=""> 
                    <TableCell>{sl+parseFloat(1)}.</TableCell>
                  <TableCell  align="left">{moment(row.created_isodt).format(dateTimeFormat)}</TableCell>
                
                  <TableCell  align="left">  {row.invoice}</TableCell>
                  {
                    row.details && row.details.map((ele, id)=> (
                      <>
                        
                        <TableCell  align="left">{ele.prod_name}</TableCell>
                        <TableCell  align="left">{ format(parseFloat(ele.prod_rate).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(ele.prod_qty).toFixed(2))}</TableCell>
                      </>
                    ))
                  }
                 
                  <TableCell  align="right">{format(parseFloat(row.total_amount).toFixed(2))}</TableCell>
            
                  <TableCell align="right" className="print-no"> 
                    <button onClick={()=>window.open(`/stock/product-damage-invoice/${row.product_damage_id}`,'_blank')}>
                    <ReceiptIcon style={{cursor:'pointer',color:'black',marginLeft:'2px'}} > </ReceiptIcon></button>
                    {
                       authInfo.role !='user'?(
                       <>
                          <button onClick={()=>window.open(`/stock/product-damage-update/${row.product_damage_id}`,'_blank')}>
                          <EditIcon style={{cursor:'pointer',color:'green',marginLeft:'2px'}} > </EditIcon></button>
                          {row.status=='a'?(
                             <DeleteIcon onClick={()=>{deleteRecord(row.product_damage_id)}} style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} > </DeleteIcon>
                              ):(
                             <SettingsBackupRestoreIcon style={{cursor:'pointer',color: 'rgb(60, 178, 194)',fontSize: '28px',}} onClick={()=>restoreRecord(row.product_damage_id)}/>
                              )
                          } 
                       </>
                      ):''
                    }
                 </TableCell>
                 
                   </TableRow>
                ))
                   
        
              }

<TableRow  key=""> 
                
                  <TableCell colSpan={5} align="right">Total : </TableCell>
                  <TableCell  align="right">
                  {format(_.sumBy(_.flatMap(report.filter((curr) => curr.status === 'a'), (curr) => curr.details), (item) => Number(item.prod_qty)).toFixed(2))}
                  </TableCell>

                 
                  <TableCell  align="right">{format(_.sumBy(report.filter((curr) => curr.status === 'a'), (curr) => Number(curr.total_amount)).toFixed(2))}
                  </TableCell>

                 
                   </TableRow>
         
          </TableBody>
        </Table>
      </TableContainer>
          </Paper>
     ):''
  }
      </div>)
  })

    return(
        <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
  marginBottom: '2px'}}>Damage  List</h4> 
  
  

<Grid container spacing={3} > 
     
        
        
        <Grid item  xs={12}  sm={2} style={{marginBottom: '-9px'}} > 
          <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
        
          <KeyboardDateTimePicker
        //  inputRef={saleDateRef}
          onKeyDown={(e)=>{
            if(e.key==='Enter'){
              //supplierRef.current.focus()
            }
          }}
          style={{ width: '100%',marginTop: '' }}
          value={byDateTime.fromDate}
          onChange={(datet)=>{
            byDateTimeSet({...byDateTime,fromDate:datet})
          }}
          label="From Date &   Time"
          format="Y/MM/d hh:mm a"
          /> 
          </MuiPickersUtilsProvider>
        </Grid>


        <Grid item  xs={12}  sm={2} style={{marginBottom: '-9px'}} > 
          <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
        
          <KeyboardDateTimePicker
          //inputRef={saleDateRef}
          onKeyDown={(e)=>{
            if(e.key==='Enter'){
             // supplierRef.current.focus()
            }
          }}
          style={{ width: '100%',marginTop: '' }}
          value={byDateTime.toDate}
          onChange={(datet)=>{
            byDateTimeSet({...byDateTime,toDate:datet})

          }}
          label="To Date &   Time"
          format="yyyy/MM/dd hh:mm a"
             />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item  xs={12}   sm={1} >
      <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'14px'}} 
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SearchIcon/>}
                          onClick={getSearchResult}
                          disabled={reportLoading?true:false}
                      >
                     Search 
                    </Button>
      </Grid>

      </Grid> 
      </Paper> 

      {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-7px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }

            <ReportDom ref={reportRef} />
</div>

    )
}
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(DamageList);