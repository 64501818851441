import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,checkIntNum,currentDateTime,getDateTimeFromISODT} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from 'sweetalert';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import { Modal } from 'react-responsive-modal';
import { Redirect,generatePath  } from 'react-router'
import {useHistory} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import EditIcon from '@material-ui/icons/Edit';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {APP_URL,API_URL} from '../../config.json';



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import socketIOClient from "socket.io-client";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import commaNumber from 'comma-number';

let format = commaNumber.bindWith(',', '.')


function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const PurchaseEntry = ({location,currentRoute,currentRouteSet,authInfo})=>{

    const classes = useStyles();
    const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
    const [saveAction, saveActionSet] = useState(false);
    const history = useHistory();
    const [progress, setProgress] = React.useState(0);
    let [selectedSupplierPayMethod,selectedSupplierPayMethodSet] = useState({pay_method_id:'cash',pay_method:'Cash'},{pay_method_id:'bank',pay_method:'Bank'})
    let [selectedSupplierPayAcc,selectedSupplierPayAccSet] = useState(null)
    let [supplierPayAccs,supplierPayAccsSet] = useState([])
    let [interim_discount, interim_discount_set] = useState(0);
    let supplierPayMethods = [{pay_method_id:'cash',pay_method:'cash'},{pay_method_id:'bank',pay_method:'bank'}]
    let [is_tds, is_tds_set] = useState(false)
    let [purchaseData,setPurchaseData] = useState({
              invoice_no:'',
              //pur_bill_no:'',
              pur_emp_id:0,
              pur_created_isodt:currentDateTime,
              pur_supplier_id:0,
              pur_subtotal_amount:0,
              pur_vat_amount:'',
              pur_vat_percent:'',
              pur_discount_amount:'',
              pur_discount_percent:'',
              pur_total_amount:0,
              pur_paid_amount:0,
              pur_due_amount:0,
              pur_transport_cost:'',
              supplier_address:'',
              supplier_mobile_no:'',
              supplier_name:'',
              pur_note:'',
              pur_previous_due:0,
              pur_supplier_type:'',
              pur_cgst_amount: '',
              pur_cgst_percent: '',
              pur_igst_amount: '',
              pur_igst_percent: '',
              pur_sgst_amount: '',
              pur_sgst_percent: '',
              supplier_gst:'',
              supplier_pan:'',
              supplier_state:'',
              supplier_state_code:'',
              pur_customer_id:0,
              customer_name:'',
              customer_address:'',
              customer_mobile_no:'',
              customer_gst:'',
              customer_pan:'',
              customer_state:'',
              customer_state_code:'',
              customer_institution_name:'',
              is_tds: 0,
              tds_value:0 ,
              tds_amt: 0,
              total_amt_deduct_tds:0 ,

            })
      
    let [fileData, setFileData] = useState(null);
    let [formAction,formActionSet] = useState('create');
    let [employees,employeesSet] = useState([]);
    let [suppliers,suppliersSet] = useState([]);
    let [products,productsSet] = useState([]);
    let [purchaseCart,purchaseCartSet] = useState([]);
    let [subTotal,subTotalSet] = useState(0);

    let [purTotalAmo,purTotalAmoSet] = useState(0);
    let [purPaidAmo,purPaidAmoSet] = useState(0);
    let [purDueAmo,purDueAmoSet] = useState(0);
    let [rounded_off,rounded_off_set] = useState('');

    let [cartTotal,cartTotalSet] = useState(0);
    let [invoice_no, invoice_no_set] = useState('');
    let [pur_vat_percent,pur_vat_percent_set] = useState('');
    let [pur_vat_amount,pur_vat_amount_set] = useState('');
    let [pur_discount_percent,pur_discount_percent_set] = useState('');
    let [pur_discount_amount,pur_discount_amount_set] = useState('');
    let [pur_cgst_amount, pur_cgst_amount_set] = useState('')
    let [pur_cgst_percent, pur_cgst_percent_set] = useState('')
    let [pur_sgst_amount, pur_sgst_amount_set] = useState('')
    let [pur_sgst_percent, pur_sgst_percent_set] = useState('')
    let [pur_igst_amount, pur_igst_amount_set] = useState('')
    let [pur_igst_percent, pur_igst_percent_set] = useState('')
    let [updateCartBtn, setUpdateCartBtn] = useState(false)
    let [addCartBtn, setAddCartBtn] = useState(true)
    let [productUpdateIndex, setProductUpdateIndex] = useState(0)
    let [purchaseMaterialM, purchaseMaterialMSet] = useState(false)
    let [purchase_material_name, purchase_material_name_set] = useState('')
    let [purchaseMaterialAction, purchaseMaterialActionSet] = useState(false)

    let [selectedEmployee,selectedEmployeeSet] = useState(null)
    let [selectedSupplier,selectedSupplierSet] = useState(null)
    let [selectedProduct,selectedProductSet] = useState(null)
    let [individualProducts,setIndividualProducts] = useState([])


    let [prod_discount_amount,prod_discount_amount_set] = useState('');
    let [prod_cgst_amount, prod_cgst_amount_set] = useState('')
    let [prod_cgst_percent, prod_cgst_percent_set] = useState('')
    let [prod_sgst_amount, prod_sgst_amount_set] = useState('')
    let [prod_sgst_percent, prod_sgst_percent_set] = useState('')
    let [prod_igst_amount, prod_igst_amount_set] = useState('')
    let [prod_igst_percent, prod_igst_percent_set] = useState('')


    let employeeRef = React.useRef(null)
    let purchaseDateRef = React.useRef(null)
    let supplierRef = React.useRef(null)
    let purchaseRateRef = React.useRef(null)
    let pcgstPercentRef = React.useRef(null)
    let pcgstRef = React.useRef(null)
    let psgstPercentRef = React.useRef(null)
    let psgstRef = React.useRef(null)
    let pigstPercentRef = React.useRef(null)
    let ptotalRef = React.useRef(null)
    let pigstRef = React.useRef(null)
    let productRef = React.useRef(null)
    let saleRateRef = React.useRef(null)
    let quantityRef = React.useRef(null)
    let totalRef = React.useRef(null)
    let purchaseToCartRef = React.useRef(null)
    let savePurchaseRef = React.useRef(null)
    let subTotalRef = React.useRef(null)
    let vatRef = React.useRef(null)
    let discountRef = React.useRef(null)
    let discountRefPercentage = React.useRef(null)
    let cgstRefAmount = React.useRef(null)
    let cgstRefPercent = React.useRef(null)
    let sgstRefAmount = React.useRef(null)
    let sgstRefPercent = React.useRef(null)
    let igstRefAmount = React.useRef(null)
    let igstRefPercent = React.useRef(null)
    let transportRef = React.useRef(null)
    let purTotalRef = React.useRef(null)
    let paidRef = React.useRef(null)
    let dueRef = React.useRef(null)
    let hsnRef = React.useRef(null)
    let previousDueRef = React.useRef(null)
   let [purchase_material_names, purchase_material_names_set] = useState([])
   let [selectedPurchaseMaterialName, setSelectedPurchaseMaterialName] = useState(null)
   let [purchase_to, purchase_to_set] = useState('Supplier')
   let [customers, setCustomers] = useState([])
   let [selectedCustomer, setSelectedCustomer] = useState(null)
  


   let getCustomers = async() => {
    await axios.post(`${API_URL}/api/get-customers`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
      res.data.message.unshift({customer_id:0,customer_name:'General customer',display_text:'General customer',customer_mobile_no:'',customer_address:'',customer_type:'general'})
      setCustomers(res.data.message)
     });
   }


    const getPurchaseMaterialName = async ()=>{
      await axios.post(`${API_URL}/api/get-purchase-material-names`,{'select-type':'a'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        purchase_material_names_set(res.data.message);
      })
   }

    const savePurchaseMaterialNameAction = async ()=>{
      console.log('save func front called');
      if(purchase_material_name.trim()==''){
        swal({
          title:'name is required',
          icon:'warning'
        })
      }else{
       purchaseMaterialActionSet(true)
        await axios.post(`${API_URL}/api/purchase-material-name-cu`,{purchase_material_name:purchase_material_name.trim(),action:'create'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          purchaseMaterialActionSet(false)
          console.log(res.data,"Save Step in Modal front");
          if(res.data.error){
            swal({
              title:res.data.message,
              icon:'warning'
            });
            return false;
          }
          getPurchaseMaterialName();
          purchaseMaterialMSet(false)
          purchase_material_name_set('')
       })
      }
      
      }
    
    const handleFromInput = (e)=>{
      const {name,value} = e.target;
      setPurchaseData({...purchaseData,[name]:value}) 
    }

    let [product,setProduct] = useState({
      prod_id: 0,
      prod_name:'',
      prod_purchase_rate:'',
      prod_sale_rate: '',
      prod_qty: '',
      prod_total: 0,
      prod_discount:0,
      prod_cgst_amount:0,
      prod_cgst_percent:0,
      prod_igst_amount:0,
      prod_igst_percent:0,
      prod_sgst_amount:0,
      prod_sgst_percent:0,
      prod_hsn:'',
      });
    
    const productHandle = (e)=>{
      const {name,value} = e.target;
      setProduct({...product,[name]:value}) 

      let total = product.prod_purchase_rate * product.prod_qty;
      let productTotal = (total - ((parseFloat(total) * parseFloat(product.prod_discount))/100) )

      if(e.target.name=='prod_cgst_amount'){  
        prod_cgst_amount_set(e.target.value)
        let vatPercent = (100 * parseFloat(e.target.value))/parseFloat(productTotal)
        prod_cgst_percent_set(vatPercent.toFixed(2))
        setProduct({...product,prod_cgst_percent: prod_cgst_percent, prod_cgst_amount: prod_cgst_amount })
      }else if(e.target.name=='prod_cgst_percent'){
       
        prod_cgst_percent_set(e.target.value)
        let vatAmount = (parseFloat(productTotal)*parseFloat(e.target.value))/100;
        prod_cgst_amount_set(vatAmount.toFixed(2))
        setProduct({...product,prod_cgst_percent: prod_cgst_percent,prod_cgst_amount: prod_cgst_amount })
      }

      else if(e.target.name=='prod_igst_amount'){  
        prod_igst_amount_set(e.target.value)
        let vatPercent = (100 * parseFloat(e.target.value))/parseFloat(productTotal)
        prod_igst_percent_set(vatPercent.toFixed(2))
        setProduct({...product,prod_igst_percent: prod_igst_percent, prod_igst_amount: prod_igst_amount })
      }else if(e.target.name=='prod_igst_percent'){
        prod_igst_percent_set(e.target.value)
        let vatAmount = (parseFloat(productTotal)*parseFloat(e.target.value))/100;
        prod_igst_amount_set(vatAmount.toFixed(2))
        setProduct({...product,prod_igst_percent: prod_igst_percent, prod_igst_amount: prod_igst_amount })
      }

      else if(e.target.name=='prod_sgst_amount'){  
        prod_sgst_amount_set(e.target.value)
        let vatPercent = (100 * parseFloat(e.target.value))/parseFloat(productTotal)
        prod_sgst_percent_set(vatPercent.toFixed(2))
        setProduct({...product,prod_sgst_percent: prod_sgst_percent, prod_sgst_amount: prod_sgst_amount })
      }else if(e.target.name=='prod_sgst_percent'){
        prod_sgst_percent_set(e.target.value)
        let vatAmount = (parseFloat(productTotal)*parseFloat(e.target.value))/100;
        prod_sgst_amount_set(vatAmount.toFixed(2))
        setProduct({...product,prod_sgst_percent: prod_sgst_percent, prod_sgst_amount: prod_sgst_amount })
      }

      //console.log(product)

      let currtotal = parseFloat(productTotal) + parseFloat(prod_cgst_amount) + parseFloat(prod_sgst_amount) + parseFloat(prod_igst_amount);
      cartTotalSet(currtotal)
    }

    

    const handleTotalInput = (e)=>{
      const {name,value} = e.target;
      setPurchaseData({...purchaseData,[name]:value}) 
    }

    const handlePaidInput = (e)=>{
      const {name,value} = e.target;
      purPaidAmoSet(value)
    }

    const handleDueInput = (e)=>{
      const {name,value} = e.target;
      purDueAmoSet(value)
    }

  

    useEffect(()=>{
      if(selectedSupplier!=null && selectedSupplier.supplier_id==0){
        purPaidAmoSet(purTotalAmo);
        purDueAmoSet(0);
      }else{
        let dueAmount = purTotalAmo-purPaidAmo;
        purDueAmoSet(dueAmount);
      }
    },[handlePaidInput,handleDueInput,purTotalAmo]);

    useEffect(()=>{
      let total = product.prod_purchase_rate * product.prod_qty;
      let productTotal = (total - ((parseFloat(total) * parseFloat(product.prod_discount))/100) )
      
      let currtotal = parseFloat(productTotal) + parseFloat(prod_cgst_amount) + parseFloat(prod_sgst_amount) + parseFloat(prod_igst_amount);
      cartTotalSet(currtotal)
      //cartTotalSet(productTotal);
    },[productHandle])

 

     
    const purchaseToCart = ( async ()=>{
          if(selectedPurchaseMaterialName==null){
            swal({title:'Select  purchase material',icon:'warning'});return false;
          }
          if(product.prod_purchase_rate < 0.1){
            await swal({title:'Purchase rate is 0. Are you sure to add to cart?',icon:'warning'});
          }
          if(product.prod_qty < 1){
            swal({title:'Quantity is invalid.',icon:'warning'});return false;
          }
          if(product.prod_sale_rate < 0){
            swal({title:'sale rate is invalid.',icon:'warning'});return false;
          }

          product.prod_total = cartTotal;
          product.prod_cgst_amount = prod_cgst_amount;
          product.prod_cgst_percent = prod_cgst_percent;
          product.prod_sgst_amount = prod_sgst_amount;
          product.prod_sgst_percent = prod_sgst_percent;
          product.prod_igst_amount = prod_igst_amount;
          product.prod_igst_percent = prod_igst_percent;
          console.log(product)
          let checkExit =   purchaseCart.findIndex((ele)=>{
              if(ele.prod_id==selectedPurchaseMaterialName.purchase_material_id){ 
                return true 
              }else{
                return false
              }
                  
          });
          if(checkExit>-1){
            swal({title:"This purchase material already Exist.",icon:'warning'})
            return false;
          }
          purchaseCartSet([...purchaseCart,product]);
          setProduct({...product,prod_purchase_rate:'',
            prod_sale_rate:0,prod_id:0,prod_name:'',prod_qty:'', prod_discount:0, prod_cgst_amount:'', prod_cgst_percent:'', prod_sgst_amount:'', prod_sgst_percent:'', prod_igst_amount:'', prod_igst_percent:'',prod_hsn:''});

          setSelectedPurchaseMaterialName(null)
          
          selectedProductSet(null);
          prod_cgst_amount_set('')
          prod_cgst_percent_set('')
          prod_sgst_amount_set('')
          prod_sgst_percent_set('')
          prod_igst_amount_set('')
          prod_igst_percent_set('')
    })

    useEffect(()=>{
      let total =  purchaseCart.reduce((prev,curr)=>{
        return prev+parseFloat(curr.prod_total);
    },0)
    subTotalSet(total)
    pur_discount_amount_set(parseFloat(total)*parseFloat(pur_discount_percent)/100.0)
   // pur_discount_percent_set('')
    pur_vat_amount_set('')
    pur_vat_percent_set('')
    pur_cgst_amount_set(parseFloat(total)*parseFloat(pur_cgst_percent)/100.0)
   // pur_cgst_percent_set('')
    pur_igst_amount_set(parseFloat(total)*parseFloat(pur_igst_percent)/100.0)
   // pur_igst_percent_set('')
    pur_sgst_amount_set(parseFloat(total)*parseFloat(pur_sgst_percent)/100.0)
   // pur_sgst_percent_set('')
    },[purchaseCart]) 

    useEffect(()=>{
      setPurchaseData({...purchaseData,pur_subtotal_amount:subTotal})

    },[subTotal])

    useEffect( ()=>{
      currentRouteSet(pathSpliter(location.pathname,1));
      //getPurchaseInvoice();
      getPurchaseMaterialName();
      getEmployees();
      getSuppliers();
      getIndividualProducts()
      getCustomers();
      const socket = socketIOClient(API_URL);
      // real time
      socket.on("purchaseInvoice",(data)=>{
        setPurchaseData({...purchaseData,invoice_no:data})
      })

      if(pathSpliter(location.pathname,3) != undefined){
        formActionSet('update')
         axios.post(`${API_URL}/api/get-purchase-material-with-details`,{reqPayload:{purchaseId:parseFloat(pathSpliter(location.pathname,3))}},{headers:{'auth-token':authInfo.token}}).then(res=>{
            let purchase = res.data.message[0];
            let  purchaseDetails = res.data.message[0].details;
            console.log(JSON.stringify(purchase)+ "hi" + JSON.stringify(purchaseDetails));
            selectedEmployeeSet({
              employee_id: purchase.pur_emp_id,
              employee_name: purchase.employee_name
            });

            selectedSupplierSet({
              supplier_id: purchase.pur_supplier_id,
              supplier_name: purchase.pur_supplier_type =='general'?'General Supplier':purchase.supplier_name,
              supplier_type: purchase.pur_supplier_type,
              display_text: purchase.supplier_name,
              supplier_tax_value: purchase.tds_value,
            })

            setSelectedCustomer({
              customer_id: purchase.pur_customer_id,
              customer_name: purchase.customer_name
            })



            setPurchaseData({...purchaseData,invoice_no:purchase.pur_invoice_no,supplier_name:purchase.supplier_name,display_text:purchase.supplier_name,
              supplier_mobile_no:purchase.supplier_mobile_no,supplier_address:purchase.supplier_address,
              pur_transport_cost:purchase.pur_transport_cost,
              pur_supplier_type : purchase.pur_supplier_type,
              pur_supplier_id : purchase.pur_supplier_id,
              supplier_gst: purchase.supplier_gst,
              supplier_pan: purchase.supplier_pan,
              supplier_state:purchase.supplier_state,
              supplier_state_code: purchase.supplier_state_code,
              pur_customer_id: purchase.pur_customer_id,
              customer_address: purchase.customer_address,
              customer_name:purchase.customer_name,
              customer_mobile_no:purchase.customer_mobile_no,
              customer_gst:purchase.customer_gstin,
              customer_pan:purchase.customer_pan_no,
              customer_state:purchase.customer_state,
              customer_state_code:purchase.customer_state_code,
              customer_institution_name: purchase.customer_institution_name,
              is_tds: purchase.is_tds,
              tds_amt: purchase.tds_amt,
              total_amt_deduct_tds: purchase.total_amt_deduct_tds,
              //pur_bill_no:purchase.pur_bill_no,
              
              })
              is_tds_set(purchase.is_tds)
              rounded_off_set(purchase.pur_transport_cost+"")
              invoice_no_set(purchase.pur_invoice_no)
              handleDateChangeSet(purchase.pur_created_isodt)
              pur_vat_amount_set(purchase.pur_vat_amount)
              pur_vat_percent_set(purchase.pur_vat_percent)
              pur_cgst_amount_set(purchase.pur_cgst_amount)
              pur_cgst_percent_set(purchase.pur_cgst_percent)
              pur_sgst_amount_set(purchase.pur_sgst_amount)
              pur_sgst_percent_set(purchase.pur_sgst_percent)
              pur_igst_amount_set(purchase.pur_igst_amount)
              pur_igst_percent_set(purchase.pur_igst_percent)
              pur_discount_amount_set(purchase.pur_discount_amount)
              subTotalSet(purchase.pur_subtotal_amount)
              pur_discount_percent_set(purchase.pur_discount_percent)
              purTotalAmoSet(purchase.pur_total_amount)
              purPaidAmoSet(purchase.pur_paid_amount)
              purDueAmoSet(purchase.pur_due_amount)
              //pur_bill_no_set(purchase.pur_bill_no)
              


              selectedSupplierPayMethodSet({pay_method_id:purchase.pur_pay_method,pay_method:purchase.pur_pay_method})
              selectedSupplierPayAccSet({bank_display_name:purchase.bank_display_name,bank_acc_id:purchase.pur_bank_id})

           let purchaseCartGet =  purchaseDetails.map((ele)=>{ 
              let product = {
                prod_id:ele.pur_prod_id,
                prod_name:ele.prod_name,
                prod_purchase_rate:ele.pur_rate,
                prod_sale_rate:ele.sale_rate,
                prod_qty:ele.pur_qty,
                prod_total:ele.pur_total_amount,
                prod_discount: ele.pur_prod_discount,
                prod_cgst_amount: ele.pur_prod_cgst_amount,
                prod_cgst_percent: ele.pur_prod_cgst_percent,
                prod_sgst_amount: ele.pur_prod_sgst_amount,
                prod_sgst_percent: ele.pur_prod_sgst_percent,
                prod_igst_amount: ele.pur_prod_igst_amount,
                prod_igst_percent: ele.pur_prod_igst_percent,
                prod_hsn: ele.prod_hsn,
              }
              return product;
            });

            purchaseCartSet(purchaseCartGet)




        })

       
      }
      getTranAccs()
    },[]) 


    const getTranAccs = async ()=>{
      await axios.post(`${API_URL}/api/get-bank-accounts`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        supplierPayAccsSet(res.data.message)
      })
     } 


    let savePurchase = async ()=>{

       if(selectedSupplier==null && selectedCustomer==null){
            swal({title:'Supplier/Customer is Required.',icon:'warning'}) 
            return false;
        }

        if(purchaseCart.length==0){
          swal({title:'Sorry... your cart is Empty.',icon:'warning'}) 
          return false;
        }
        if(invoice_no == null){
          swal({title:'Enter bill number',icon:'warning'});return false;
        }



        if(purPaidAmo > 0 && selectedSupplierPayMethod == null){
          swal({
            title:'Select Payment   Method',
            icon:'warning'
          });
          return false;
        }

        if(purPaidAmo > 0 &&  selectedSupplierPayMethod != null && selectedSupplierPayMethod.pay_method_id=='bank' && selectedSupplierPayAcc == null ){
          swal({
            title:'Select  Payment  Account ',
            icon:'warning'
          });
          return false;
        }

        if(parseFloat(purPaidAmo) <= 0 && selectedSupplierPayMethod !=null && selectedSupplierPayMethod.pay_method_id =='bank'){
          swal({
            title:'Paymentable Amount is 0 ',
            icon:'warning'
          });
          return false;
        }

        // if(!fileData){
        //   swal({
        //     title:'Please choose file to upload ',
        //     icon:'warning'
        //   });
        //   return false;
        // }
        
       let purId = pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0;
       let purchase =  {...purchaseData,pur_vat_amount:pur_vat_amount,pur_vat_percent:pur_vat_percent,
        pur_cgst_amount:pur_cgst_amount, pur_cgst_percent:pur_cgst_percent,
        pur_igst_percent:pur_igst_percent, pur_igst_amount:pur_igst_amount,
        pur_sgst_percent:pur_sgst_percent, pur_sgst_amount:pur_sgst_amount,
        pur_discount_amount:pur_discount_amount,pur_discount_percent:pur_discount_percent,
        pur_due_amount:purDueAmo,pur_paid_amount:purPaidAmo,
        pur_total_amount:purTotalAmo,
        pur_emp_id:(selectedEmployee!=null)?selectedEmployee.employee_id:0,
        pur_created_isodt:selectedDate,
        pur_id:purId,
        pur_supplier_id:selectedSupplier.supplier_id ,
        pur_supplier_type :  selectedSupplier.supplier_type == undefined ? 'general':selectedSupplier.supplier_type,
        pur_pay_method : selectedSupplierPayMethod !=null? selectedSupplierPayMethod.pay_method_id:'cash',
        pur_bank_id :   selectedSupplierPayMethod==null || selectedSupplierPayMethod.pay_method_id =='cash' ? 0 :selectedSupplierPayAcc.bank_acc_id,
        is_subContract : 0,
        invoice_no: invoice_no,
        pur_transport_cost: rounded_off,
        is_tds,
        tds_value:selectedSupplier.supplier_tax_value ,
        tds_amt: parseFloat(parseFloat(selectedSupplier.supplier_tax_value)*parseFloat(purchaseData.pur_subtotal_amount)/100.0).toFixed(2),
        total_amt_deduct_tds: parseFloat(parseFloat(purchaseData.pur_subtotal_amount) - parseFloat(parseFloat(selectedSupplier.supplier_tax_value)*parseFloat(purchaseData.pur_subtotal_amount)/100.0)).toFixed(2) ,

        //pur_bill_no:pur_bill_no,
        }
        console.log(purchase);

        const formData = new FormData();
        formData.append('fileData', fileData);
        formData.append('purchase', JSON.stringify(purchase));
        formData.append('purchaseCart',JSON.stringify( purchaseCart));
        
        saveActionSet(true)
        let url = `/save-purchase-material`;
        if(pathSpliter(location.pathname,3) != undefined){
            url =  `/update-purchase-material`;
        }

        await axios.post(`${API_URL}/api${url}`,
        formData,
        {
          headers:{
            'Content-Type': 'multipart/form-data',
            'auth-token':authInfo.token
          },
          onUploadProgress: progressEvent => {
            setProgress(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
  
            // Clear percentage
            setTimeout(() => setProgress(0), 10000);
          }
        }
        ).then(res=>{
          if(!res.data.error){
            let smg = 'Purchase ';
            if(pathSpliter(location.pathname,3) != undefined){
              smg = 'Purchase updated '
            }
            swal({
              title:`${smg} successfully. Do you want to view invoice?`,
              icon:'success',
              buttons:true
            }).then((confirm)=>{
              if(confirm){
                history.push(`/purchase/purchase-invoice/${res.data.message.purchaseId}`) 
                saveActionSet(false)
              }else{
                window.location.reload();
              }
            })
          }
          else{
            swal({title:`Your Network problem....${res.data.message}`,icon:'warning'})
          }
        })

      
    }


    /// Methods 
    const getPurchaseInvoice = async ()=>{ 
          await axios.get(`${API_URL}/api/get-purchase-invoice`,{headers:{'auth-token':authInfo.token}}).then(res=>{
                setPurchaseData({...purchaseData,invoice_no:res.data.message})
          })
    }

    const getSuppliers = async ()=>{
          await axios.post(`${API_URL}/api/get-suppliers-purchase`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
            res.data.message.unshift({supplier_id:0,supplier_address:'',supplier_mobile_no:'',supplier_name:'General Supplier',display_text:'General Supplier'});
            suppliersSet(res.data.message);
          })
    }

    const getIndividualProducts = async()=>{
          await axios.post(`${API_URL}/api/get-individual-products`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
            setIndividualProducts(res.data.message);
            //console.log(res.data.message);
          })
    }

    const getEmployees = async ()=>{
      await axios.post(`${API_URL}/api/get-employees`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
        employeesSet(res.data.message)
    })
    }



   
    const  purchaseCartRemove = (row,index)=>{
      let hardCopy =  [...purchaseCart];
          hardCopy.splice(index, 1);
          purchaseCartSet(hardCopy)
    }

    const updatePurchaseCart = async () => {
      if(selectedPurchaseMaterialName==null){
        swal({title:'Select  purchase material',icon:'warning'});return false;
      }
      if(product.prod_purchase_rate < 0.1){
        await swal({title:'Purchase rate is 0. Are you sure to add to cart?',icon:'warning'});
      }
      if(product.prod_qty < 1){
        swal({title:'Quantity is invalid.',icon:'warning'});return false;
      }
      if(product.prod_sale_rate < 0){
        swal({title:'sale rate is invalid.',icon:'warning'});return false;
      }
      
      product.prod_total = cartTotal;
      product.prod_cgst_amount = prod_cgst_amount;
      product.prod_cgst_percent = prod_cgst_percent;
      product.prod_sgst_amount = prod_sgst_amount;
      product.prod_sgst_percent = prod_sgst_percent;
      product.prod_igst_amount = prod_igst_amount;
      product.prod_igst_percent = prod_igst_percent;

      // let checkExit =   purchaseCart.findIndex((ele)=>{
      //     if(ele.prod_id==selectedProduct.prod_id){ 
      //       return true 
      //     }else{
      //       return false
      //     }
              
      // });
      // if(checkExit>-1){
      //   swal({title:"This purchase material already Exist.",icon:'warning'})
      //   return false;
      // }
      let hardCopy =  [...purchaseCart];
          hardCopy.splice(productUpdateIndex, 1, product);
          purchaseCartSet(hardCopy)
      console.log(purchaseCart + "hey there purchaseCart");
      //purchaseCartSet([...purchaseCart,product]);
      setProduct({...product,prod_purchase_rate:'',
            prod_sale_rate:0,prod_id:0,prod_name:'',prod_qty:'', prod_discount:0, prod_cgst_amount:'', prod_cgst_percent:'', prod_sgst_amount:'', prod_sgst_percent:'', prod_igst_amount:'', prod_igst_percent:'',prod_hsn:''});

          setSelectedPurchaseMaterialName(null)
          
          selectedProductSet(null);
          prod_cgst_amount_set('')
          prod_cgst_percent_set('')
          prod_sgst_amount_set('')
          prod_sgst_percent_set('')
          prod_igst_amount_set('')
          prod_igst_percent_set('')
      
      selectedProductSet(null);
      setAddCartBtn(true)
      setUpdateCartBtn(false)
      //productRef.current.focus();
    }

    const  purchaseCartUpdate = (row,index)=>{
      setAddCartBtn(false)
      setUpdateCartBtn(true)
      setProductUpdateIndex(index)
      let hardCopy =  [...purchaseCart];
      const productData = purchaseCart[index];     
      setSelectedPurchaseMaterialName({purchase_material_id:productData.prod_id, purchase_material_name: productData.prod_name});
      setProduct({...product,prod_purchase_rate:productData.prod_purchase_rate,prod_sale_rate:productData.prod_sale_rate,prod_id:productData.prod_id,prod_name:productData.prod_name, prod_total:productData.pur_total_amount, prod_qty: productData.prod_qty, prod_discount: productData.prod_discount,
      prod_cgst_amount:productData.prod_cgst_amount, prod_cgst_percent: productData.prod_cgst_percent,
    prod_igst_amount:productData.prod_igst_amount, prod_igst_percent: productData.prod_igst_percent, prod_sgst_amount:productData.prod_sgst_amount, prod_sgst_percent: productData.prod_sgst_percent,
    prod_hsn:productData.prod_hsn,
  });

  prod_cgst_amount_set(productData.prod_cgst_amount)
  prod_cgst_percent_set(productData.prod_cgst_percent)
  prod_sgst_amount_set(productData.prod_sgst_amount)
  prod_sgst_percent_set(productData.prod_sgst_percent)
  prod_igst_amount_set(productData.prod_igst_amount)
  prod_igst_percent_set(productData.prod_igst_percent)
      console.log(product, productData)
      //purchaseCartSet(hardCopy)
      
    }


    let handleDiscountInput = (e)=>{
        if(e.target.name=='pur_discount_amount'){ 
          pur_discount_amount_set(e.target.value)
          let discountPercent = (100 * parseFloat(e.target.value))/parseFloat(subTotal);
          pur_discount_percent_set(discountPercent.toFixed(2));
          interim_discount_set(subTotal-e.target.value);
          pur_vat_amount_set('')
          pur_vat_percent_set('')
          pur_cgst_amount_set('')
          pur_cgst_percent_set('')
          pur_igst_amount_set('')
          pur_igst_percent_set('')
          pur_sgst_amount_set('')
          pur_sgst_percent_set('')
        }else{
          pur_discount_percent_set(e.target.value)
          let discountAmount = (parseFloat(subTotal)*parseFloat(e.target.value))/100;
          pur_discount_amount_set(discountAmount.toFixed(2));
          interim_discount_set(subTotal-discountAmount.toFixed(2));
          pur_vat_percent_set('');
          pur_vat_amount_set('');
          pur_cgst_amount_set('');
          pur_cgst_percent_set('');
          pur_igst_amount_set('');
          pur_igst_percent_set('');
          pur_sgst_amount_set('');
          pur_sgst_percent_set('');
        }

    }


    let handleVatInput = (e)=>{
      
      if(e.target.name=='pur_cgst_amount'){  
        pur_cgst_amount_set(e.target.value)
        let vatPercent = (100 * parseFloat(e.target.value))/parseFloat(subTotal-pur_discount_amount)
        pur_cgst_percent_set(vatPercent.toFixed(2))
      }else if(e.target.name=='pur_cgst_percent'){
        pur_cgst_percent_set(e.target.value)
        let vatAmount = (parseFloat(subTotal-pur_discount_amount)*parseFloat(e.target.value))/100;
        pur_cgst_amount_set(vatAmount.toFixed(2))
      }

      else if(e.target.name=='pur_igst_amount'){  
        pur_igst_amount_set(e.target.value)
        let vatPercent = (100 * parseFloat(e.target.value))/parseFloat(subTotal-pur_discount_amount)
        pur_igst_percent_set(vatPercent.toFixed(2))
      }else if(e.target.name=='pur_igst_percent'){
        pur_igst_percent_set(e.target.value)
        let vatAmount = (parseFloat(subTotal-pur_discount_amount)*parseFloat(e.target.value))/100;
        pur_igst_amount_set(vatAmount.toFixed(2))
      }

      else if(e.target.name=='pur_sgst_amount'){  
        pur_sgst_amount_set(e.target.value)
        let vatPercent = (100 * parseFloat(e.target.value))/parseFloat(subTotal-pur_discount_amount)
        pur_sgst_percent_set(vatPercent.toFixed(2))
      }else if(e.target.name=='pur_sgst_percent'){
        pur_sgst_percent_set(e.target.value)
        let vatAmount = (parseFloat(subTotal-pur_discount_amount)*parseFloat(e.target.value))/100;
        pur_sgst_amount_set(vatAmount.toFixed(2))
      }
      
      // let totalBill = (parseFloat(subTotal)-parseFloat(pur_discount_amount)+parseFloat(purchaseData.pur_transport_cost))+(parseFloat(pur_cgst_amount) + parseFloat(pur_sgst_amount) +parseFloat(pur_igst_amount))
      // purTotalAmoSet(parseFloat(totalBill));
      // console.log(purTotalAmo);

    }
    
    useEffect(()=>{
       let totalBill = 0;
       let discountTotal = parseFloat(parseFloat(subTotal)-parseFloat(pur_discount_amount));
       let gstTotal = (parseFloat(parseFloat(pur_cgst_amount) + parseFloat(pur_sgst_amount) +parseFloat(pur_igst_amount)));
       totalBill = discountTotal + gstTotal;
      purTotalAmoSet(parseFloat(totalBill));
      let roundedOff = 0;
      
      if(rounded_off[0]=='-') {
        roundedOff = parseFloat(rounded_off.substring(1))*-1; 
      }else{
        roundedOff = parseFloat(rounded_off.substring(0))
      }
      purTotalAmoSet(parseFloat(totalBill + roundedOff));
      console.log(subTotal + " " + discountTotal + " " + gstTotal + " " + purTotalAmo + " " + totalBill);
    },[subTotal, pur_discount_amount, pur_cgst_amount, pur_igst_amount, pur_sgst_amount, rounded_off]);


      return(
          <div className={classes.root}>
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '2px'}}>Purchase Entry</h4>
<Grid container spacing={3} > 
          <Grid item  xs={12} sm={2}>
            <TextField label="invoice no" variant="outlined"  size="small"  className={classes.fullWidth} value={invoice_no} onChange={(e)=>invoice_no_set(e.target.value)} name="invoice_no"  />
            {/* <TextField label="Bill no" variant="outlined"  size="small"  className={classes.fullWidth} value={pur_bill_no} onChange={(e)=> pur_bill_no_set(e.target.value)} name="pur_bill_no" /> */}
          </Grid> 

          <Grid item  xs={12}  sm={2}> 
          {/* <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={employees}
              value={selectedEmployee}
              getOptionLabel={(option) => option.employee_name}
              onChange={(event,selectedObj)=>{
                purchaseData.pur_emp_id = selectedObj!=null?selectedObj.employee_id:0
                selectedEmployeeSet(selectedObj)
              }}
              renderInput={(params) => <TextField 
              
                inputRef={employeeRef}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                      purchaseDateRef.current.focus()
                  }
                }} 
                
                {...params} 
                label="Purchase by" 
                variant="outlined"
               
                />} 
                
           /> */}

          </Grid>

          
                <Grid item  xs={0}  sm={6}>

                </Grid>
          <Grid item  xs={12}  sm={2} style={{marginBottom: '-9px'}} > 
            <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
          
            <KeyboardDateTimePicker
            inputRef={purchaseDateRef}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
                supplierRef.current.focus()
              }
            }}
            style={{ width: '100%',marginTop: '' }}
            value={selectedDate}
            onChange={handleDateChangeSet}
            label="Purchse date  time"
            format="yyyy/MM/dd hh:mm a"
               />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        </Paper>
<Grid container spacing={3} >
        <Grid item xs={12} sm={8} >
          <Paper className={classes.paper}>
          <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '3px'}}>Supplier & product information</h4>
          <RadioGroup row aria-label="purchase_to" name="position" style={{display: 'flex',flexWrap: 'initial'}}   defaultValue={purchase_to} onChange={(e)=>{ purchase_to_set(e.target.value)}} >
                  <FormControlLabel value="Supplier" control={<Radio color="primary" />} label="Supplier" />
                  <FormControlLabel value="Customer" control={<Radio color="primary" />} label="Customer" />
          </RadioGroup>
          <Grid container spacing={3} >
               { purchase_to == "Supplier" && ( <Grid item xs={12} sm={6} className={classes.plusLinkDiv}>
                <Link className={classes.plusLink} to="/purchase/suppliers-manage" >+</Link>                
                 <Autocomplete 
                autoHighlight
                size="small"
              openOnFocus={true}
              value={selectedSupplier}
              style={{width:'100%'}}
              loading={(!suppliers || suppliers.length===0)?true:false}
              options={suppliers} 
              onChange={async (event,selectedObj)=>{
                if(selectedObj==null) return false
                purchaseData.pur_supplier_id = selectedObj.supplier_id
                purchaseData.supplier_name = selectedObj.supplier_name
                purchaseData.supplier_address = selectedObj.supplier_address
                purchaseData.supplier_mobile_no = selectedObj.supplier_mobile_no != null ? selectedObj.supplier_mobile_no : ''
                purchaseData.supplier_gst = selectedObj.supplier_gst == null ? '' : selectedObj.supplier_gst
                purchaseData.supplier_pan = selectedObj.supplier_pan != null ? selectedObj.supplier_pan: ''
                purchaseData.supplier_state = selectedObj.supplier_state != null ? selectedObj.supplier_state : ''
                purchaseData.supplier_state_code = selectedObj.supplier_state_code != null ? selectedObj.supplier_state_code : ''

                await axios.post(`${API_URL}/api/get-supplier-due`,{supplierId:selectedObj.supplier_id, supplierFor:'Purchase'},{headers:{'auth-token':authInfo.token}}).then(res=>{
                  if(purchaseData.pur_supplier_id == 0){
                    setPurchaseData({...purchaseData,pur_previous_due:0,pur_supplier_type:'general'}) 
                    return false;
                  }

                  setPurchaseData({...purchaseData,pur_previous_due:res.data[0].dueAmount,pur_supplier_type:'general'}) 
                })

                selectedSupplierSet(selectedObj)
              }}
              getOptionLabel={(option) => option.display_text}
              renderInput={(params) => <TextField 

                inputRef={supplierRef} 
                onKeyDown={event => {
                  if (event.key === "Enter") {
                      productRef.current.focus()
                  }
                }} 
                {...params} 
                label="Choose a Supplier " 
                variant="outlined"
                
                />}
                />
                <TextField label="supplier name" style={{display:purchaseData.pur_supplier_type == 'general' ?'' : 'none'}} size="small" variant="outlined" name="supplier_name" className={classes.inputField} value={purchaseData.supplier_name} onChange={handleTotalInput}  disabled={purchaseData.pur_supplier_id!=0?true:false}    />
                <TextField label="supplier mobile" size="small" variant="outlined" name="supplier_mobile_no" className={classes.inputField} value={purchaseData.supplier_mobile_no} onChange={handleTotalInput}  disabled={purchaseData.pur_supplier_id!=0?true:false}    />
                <TextField label="supplier address"  size="small"  variant="outlined" name="supplier_address" className={classes.inputField} value={purchaseData.supplier_address} onChange={handleTotalInput}  disabled={purchaseData.pur_supplier_id!=0?true:false}/> 
                <TextField label="supplier contact person" size="small" variant="outlined" name="supplier_institution_name" className={classes.inputField} value={purchaseData.supplier_institution_name} onChange={handleTotalInput}  disabled={purchaseData.pur_supplier_id!=0?true:false}    />
                <TextField label="supplier GST" size="small" variant="outlined" name="supplier_gst" className={classes.inputField} value={purchaseData.supplier_gst} onChange={handleTotalInput}  disabled={purchaseData.pur_supplier_id!=0?true:false}    />
                <TextField label="supplier PAN"  size="small"  variant="outlined" name="supplier_pan" className={classes.inputField} value={purchaseData.supplier_pan} onChange={handleTotalInput}  disabled={purchaseData.pur_supplier_id!=0?true:false}/> 
                <TextField label="supplier state" size="small" variant="outlined" name="supplier_state" className={classes.inputField} value={purchaseData.supplier_state} onChange={handleTotalInput}  disabled={purchaseData.pur_supplier_id!=0?true:false}    />
                <TextField label="supplier code" size="small" variant="outlined" name="supplier_state_code" className={classes.inputField} value={purchaseData.supplier_state_code} onChange={handleTotalInput}  disabled={purchaseData.pur_supplier_id!=0?true:false}    />
                </Grid>
               )
               }
               {
                 purchase_to == "Customer" && (
                  <Grid item xs={12} sm={6} className={classes.plusLinkDiv}>
                  <Link className={classes.plusLink} to="/sales/customers-manage" >+</Link>                
                   <Autocomplete 
                  autoHighlight
                  size="small"
                  openOnFocus={true}
                  value={selectedCustomer}
                  style={{width:'100%'}}
                  options={customers} 
                  onChange={async (event,selectedObj)=>{
                  if(selectedObj==null) return false
                  purchaseData.pur_customer_id = selectedObj.customer_id
                  purchaseData.customer_name = selectedObj.customer_name
                  purchaseData.customer_address = selectedObj.customer_address
                  purchaseData.customer_mobile_no = selectedObj.customer_mobile_no
                  purchaseData.customer_gst = selectedObj.customer_gstin
                  purchaseData.customer_pan = selectedObj.customer_pan_no
                  purchaseData.customer_state = selectedObj.customer_state
                  purchaseData.customer_state_code = selectedObj.customer_state_code
                  purchaseData.customer_institution_name = selectedObj.customer_institution_name
  
                  await axios.post(`${API_URL}/api/get-customer-due`,{customerId:selectedObj.customer_id},{headers:{'auth-token':authInfo.token}}).then(res=>{
                    // previous_due_set(res.data[0].dueAmount)
                    // customer_credit_limit_set(selectedObj.customer_credit_limit)
                  })
  
                  setSelectedCustomer(selectedObj)
                }}
                getOptionLabel={(option) => option.customer_name}
                renderInput={(params) => <TextField 
  
                  inputRef={supplierRef} 
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                        productRef.current.focus()
                    }
                  }} 
                  {...params} 
                  label="Choose a Customer " 
                  variant="outlined"
                  
                  />}
                  />
                  <TextField label="customer name"  size="small" variant="outlined" name="customer_name" className={classes.inputField} value={purchaseData.customer_name} onChange={handleTotalInput}  disabled={purchaseData.pur_customer_id!=0?true:false}    />
                  <TextField label="customer mobile" size="small" variant="outlined" name="customer_mobile_no" className={classes.inputField} value={purchaseData.customer_mobile_no} onChange={handleTotalInput}  disabled={purchaseData.pur_customer_id!=0?true:false}    />
                  <TextField label="customer address"  size="small"  variant="outlined" name="customer_address" className={classes.inputField} value={purchaseData.customer_address} onChange={handleTotalInput}  disabled={purchaseData.pur_customer_id!=0?true:false}/> 
                  <TextField label="customer contact person" size="small" variant="outlined" name="customer_institution_name" className={classes.inputField} value={purchaseData.customer_institution_name} onChange={handleTotalInput}  disabled={purchaseData.pur_customer_id!=0?true:false}    />
                  <TextField label="customer GST" size="small" variant="outlined" name="customer_gst" className={classes.inputField} value={purchaseData.customer_gst} onChange={handleTotalInput}  disabled={purchaseData.pur_customer_id!=0?true:false}    />
                  <TextField label="customer PAN"  size="small"  variant="outlined" name="customer_pan" className={classes.inputField} value={purchaseData.customer_pan} onChange={handleTotalInput}  disabled={purchaseData.pur_customer_id!=0?true:false}/> 
                  <TextField label="customer state" size="small" variant="outlined" name="customer_state" className={classes.inputField} value={purchaseData.customer_state} onChange={handleTotalInput}  disabled={purchaseData.pur_customer_id!=0?true:false}    />
                  <TextField label="customer code" size="small" variant="outlined" name="customer_state_code" className={classes.inputField} value={purchaseData.customer_state_code} onChange={handleTotalInput}  disabled={purchaseData.pur_customer_id!=0?true:false}    />
                  </Grid>

                 )
               }


                <Grid item xs={12} sm={6} className={classes.plusLinkDiv}>
                <Grid item xs={12} sm={12} className={classes.plusLinkDiv}>
                  {
                    authInfo.role !='user'?(
                    <>
                    <a className={classes.plusLink} style={{cursor:'pointer'}} onClick={(e)=>purchaseMaterialMSet(true)} >+</a>      
                    </>):''
                  }         
                    <Autocomplete
                        style={{ width: '100%',height:'20px' }}
                        options={purchase_material_names} 
                        size="small"
                        openOnFocus={true}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.purchase_material_name}
                        getOptionSelected={(option, value) => {
                            return option.purchase_material_id === value.purchase_material_id;
                        }}
                        onChange={(event,selectedObj)=>{
                          setSelectedPurchaseMaterialName(selectedObj)
                           setProduct({...product,prod_id:selectedObj.purchase_material_id,
                          prod_name: selectedObj.purchase_material_name})    
                          if(selectedObj==null)return false
                        }}
                        value={selectedPurchaseMaterialName}
                        loading={purchase_material_names.length==0?true:false}
                        renderInput={(params) => (
                            <TextField
                            inputRef={productRef}
                            onKeyDown={event => {
                              if (event.key === "Enter") {
                                purchaseRateRef.current.focus()

                              }
                            }}
                            {...params}
                            label="Choose a Purchase Material"
                            variant="outlined"
                            loading={purchase_material_names.length==0?true:false}
                            inputProps={{
                                ...params.inputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {materialNames.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment} */}
                                  </React.Fragment>
                                )
                            }}
                            />
                        )}
                        />
                </Grid>
                      {/* Steps Add Modal */}
                          <Modal
                              open={purchaseMaterialM}
                              onClose={() => purchaseMaterialMSet(false)}
                              center
                              style={{minWidth:'300px',minHeight:'500px'}}
                            >

                          <Grid item xs={12} sm={12}  > 
                                <TextField  autoComplete='off' style={{marginTop:'10px'}}   className={classes.fullWidth}  value={purchase_material_name} onChange={(e)=>purchase_material_name_set(e.target.value)}
                                label="Create a purchase material"  type="text" name="purchase_material_name" variant="outlined" size="small"
                                onKeyDown={(event)=>{
                                  if (event.key === "Enter") {
                                    savePurchaseMaterialNameAction()
                                  }  
                                }}
                          />
                          

                              <Button style={{marginTop: '25px'}}
                              variant="contained"
                              color="primary"
                              disabled={purchaseMaterialAction?true:false}
                              size="small"
                              className={classes.button}
                              startIcon={<SaveIcon/>}
                              onClick={savePurchaseMaterialNameAction}
                              >
                              Save
                            </Button>
                          </Grid>
                          </Modal>
 
        <br/>
                <Grid container >
                      <Grid item xs={12} sm={6}>
                      <TextField style={{marginRight:'5px'}}  
                            inputRef={purchaseRateRef}
                            onKeyDown={event => {
                              if (event.key === "Enter") {
                                  quantityRef.current.focus()
                              }
                            }}  type="number" label="purchase rate"  size="small" value={product.prod_purchase_rate} onChange={productHandle} name="prod_purchase_rate" variant="outlined" className={classes.inputField} />
                      </Grid>
                      <Grid item sm={1} >
                      </Grid>
                      <Grid item xs={12} sm={5} >
                      <TextField label="quantity"  type="number"
                        inputRef={quantityRef}
                        onKeyDown={event => {
                          if (event.key === "Enter") {
                            saleRateRef.current.focus()
                          }
                        }}
                        value={product.prod_qty}
                      variant="outlined"  size="small"   onChange={productHandle} name="prod_qty" className={classes.inputField} />
                      </Grid>
                </Grid>


                <Grid container >
                      <Grid item xs={12} sm={6}>
                      <TextField style={{marginRight:'5px'}} 
                      type="number"
                      inputRef={saleRateRef}
                      value={product.prod_discount}
                      onKeyDown={event => {
                        if (event.key === "Enter") {
                          hsnRef.current.focus()
                        }
                      }}
                      label="Discount(in %)"  size="small"  onChange={productHandle} name="prod_discount"  variant="outlined" className={classes.inputField} />
                      </Grid>

                      <Grid item sm={1} > 
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      <TextField style={{marginRight:'5px'}} 
                      type="text"
                      inputRef={hsnRef}
                      value={product.prod_hsn}
                      onKeyDown={event => {
                        if (event.key === "Enter") {
                          pcgstPercentRef.current.focus()
                        }
                      }}
                      label="HSN"  size="small"  onChange={productHandle} name="prod_hsn"  variant="outlined" className={classes.inputField} />
                      </Grid>

                      <Grid item sm={1} > 
                      </Grid>

                      <Grid container >
                        <Grid item xs={12} sm={6}>
                        <TextField style={{marginRight:'5px'}} 
                        type="number"
                        value={prod_cgst_percent}
                        label="CGST(in %)"  size="small"
                        onChange={productHandle}
                        name="prod_cgst_percent"  
                        variant="outlined"
                        className={classes.inputField}
                        inputRef={pcgstPercentRef}
                        onKeyDown={event => {
                          if (event.key === "Enter") {
                            pcgstRef.current.focus()
                          }
                        }}
                         />
                        </Grid>

                        <Grid item sm={1} > 
                        </Grid>

                        <Grid item xs={12} sm={5}>
                        <TextField style={{marginRight:'5px'}} 
                        type="number"
                        value={prod_cgst_amount}
                        label="CGST(in Amt)"  size="small" inputRef={pcgstRef} 
                        onKeyDown={event => {
                          if (event.key === "Enter") {
                            psgstPercentRef.current.focus()
                          }
                        }}
                          onChange={productHandle} name="prod_cgst_amount"  variant="outlined" className={classes.inputField} />
                        </Grid>
                      </Grid>

                      <Grid container >
                        <Grid item xs={12} sm={6}>
                        <TextField style={{marginRight:'5px'}} 
                        type="number"
                        value={prod_sgst_percent}
                        label="SGST(in %)"  size="small" 
                        inputRef={psgstPercentRef}
                        onKeyDown={event => {
                          if (event.key === "Enter") {
                            psgstRef.current.focus()
                          }
                        }}
                         onChange={productHandle} name="prod_sgst_percent"  variant="outlined" className={classes.inputField} />
                        </Grid>

                        <Grid item sm={1} > 
                        </Grid>

                        <Grid item xs={12} sm={5}>
                        <TextField style={{marginRight:'5px'}} 
                        type="number"
                        value={prod_sgst_amount}
                        label="SGST(in Amt)" 
                        inputRef={psgstRef}
                        onKeyDown={event => {
                          if (event.key === "Enter") {
                            pigstPercentRef.current.focus()
                          }
                        }}
                         size="small"  onChange={productHandle} name="prod_sgst_amount"  variant="outlined" className={classes.inputField} />
                        </Grid>
                      </Grid>

                      <Grid container >
                        <Grid item xs={12} sm={6}>
                        <TextField style={{marginRight:'5px'}} 
                        type="number"
                        value={prod_igst_percent}
                        label="IGST(in %)"  
                        inputRef={pigstPercentRef}
                        onKeyDown={event => {
                          if (event.key === "Enter") {
                            pigstRef.current.focus()
                          }
                        }}
                        size="small"  onChange={productHandle} name="prod_igst_percent"  variant="outlined" className={classes.inputField} />
                        </Grid>

                        <Grid item sm={1} > 
                        </Grid>

                        <Grid item xs={12} sm={5}>
                        <TextField style={{marginRight:'5px'}} 
                        type="number"
                        value={prod_igst_amount}
                        label="IGST(in Amt)" 
                        inputRef={pigstRef}
                        onKeyDown={event => {
                          if (event.key === "Enter") {
                            ptotalRef.current.focus()
                          }
                        }}
                         size="small"  onChange={productHandle} name="prod_igst_amount"  variant="outlined" className={classes.inputField} />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={6} >
                      <TextField label="total"
                      inputRef={ptotalRef}
                      onKeyDown={event => {
                        if (event.key === "Enter") {
                          purchaseToCartRef.current.focus()
                        }
                      }}
                       type="number" disabled value={cartTotal}  onChange={productHandle} name="prod_total"  size="small"  variant="outlined" className={classes.inputField} />
                      </Grid>
                      <Button 
                      buttonRef={purchaseToCartRef}
                      onClick={purchaseToCart}
                      disabled={!addCartBtn}
                      style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                        >
                       Add  To Cart 
                      </Button>
                      <Button 
                      buttonRef={purchaseToCartRef}
                      onClick={updatePurchaseCart}
                      disabled={!updateCartBtn}
                      style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                        >
                        Update Cart 
                      </Button>
                </Grid> 
                </Grid>
                </Grid>
          </Paper> 
          <Grid container>
            
            <Grid item xs={12}>
                  <Paper className={classes.paper} style={{marginTop:'5px'}}>
                  <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '3px'}}>Purchase Cart</h4>
                  <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">SL</TableCell>
            <TableCell align="left">Product</TableCell>
            <TableCell align="center">Purchase Rate</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center">Discount(%)</TableCell>
            <TableCell align="center">CGST(Amt)</TableCell>
            <TableCell align="center">CGST(%)</TableCell>
            <TableCell align="center">SGST(Amt)</TableCell>
            <TableCell align="center">SGST(%)</TableCell>
            <TableCell align="center">IGST(Amt)</TableCell>
            <TableCell align="center">IGST(%)</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Edit</TableCell>
            <TableCell align="right">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 
           purchaseCart.map((row,index) => ( 
            <TableRow key={row.prod_id}>
              <TableCell  align="left">{parseFloat(index)+1}</TableCell>
              <TableCell align="left">{row.prod_name}</TableCell> 
              <TableCell align="center">{format(parseFloat(row.prod_purchase_rate).toFixed(2))}</TableCell> 
              <TableCell align="center">{row.prod_qty}</TableCell>
              <TableCell align="center">{row.prod_discount}</TableCell>
              <TableCell align="center">{row.prod_cgst_amount}</TableCell>
              <TableCell align="center">{row.prod_cgst_percent}</TableCell>
              <TableCell align="center">{row.prod_sgst_amount}</TableCell>
              <TableCell align="center">{row.prod_sgst_percent}</TableCell>
              <TableCell align="center">{row.prod_igst_amount}</TableCell>
              <TableCell align="center">{row.prod_igst_percent}</TableCell>
              <TableCell align="right">{format(parseFloat(row.prod_total).toFixed(2))}</TableCell>
              <TableCell align="right" >
              <EditIcon style={{cursor:'pointer',color:'green',marginLeft:'2px'}} onClick={()=>{purchaseCartUpdate(row,index)}}></EditIcon></TableCell>
               <TableCell align="right" >
              <RemoveCircleIcon style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} onClick={()=>{purchaseCartRemove(row,index)}}></ RemoveCircleIcon></TableCell>
            </TableRow>
          ))}

        {purchaseCart.length!=0?
        <TableRow colSpan={4}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell style={{fontWeight:'bold'}} align="right">Total : {format(parseFloat(subTotal).toFixed(2))}</TableCell>
         <TableCell></TableCell>
        </TableRow>
        :''}


        </TableBody>
      </Table>
    </TableContainer>


    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
      {purchaseCart.length!=0?
          <TableRow >
             <TableCell style={{width: '325px'}}><TextareaAutosize onChange={handleTotalInput} name="pur_note" style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Purchase Note..." rowsMin={3} placeholder="purchase Note..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
          :''}
      </Table>
    </TableContainer>


                  </Paper>
            </Grid>
          
          </Grid>
        
        </Grid>

        <Grid item xs={12} sm={4} >
        
          <Paper className={classes.paper}>
          <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '3px'}}>Amount Details</h4>
          <Grid container style={{paddingRight:'1px'}}>
                      <Grid item xs={12} sm={5}>
                      <TextField type="number" style={{marginRight:'5px'}} label="Subtotal"  
                       size="small"  disabled 
                      value={purchaseData.pur_subtotal_amount}
                      inputRef={subTotalRef} 
                      onChange={handleTotalInput}
                      variant="outlined" className={classes.inputField} 
                      onKeyDown={(e)=>e.key==='Enter'?discountRefPercentage.current.focus():false}
                      />
                      </Grid>
                      <Grid item xs={0} sm={1}>
                      </Grid>


                      <Grid item xs={12} sm={6}>
                      <TextField type="number" style={{marginRight:'5px'}}  size="small"  label="Current Due" disabled variant="outlined" className={classes.inputField} 
                      value={purchaseData.pur_previous_due} 
                      onChange={handleTotalInput}
                      name="pur_previous_due"
                  
                     />
                      </Grid>


                     
                      
                     
                     
          </Grid>

          
          <Grid container style={{paddingRight:'1px'}}>
                      
                      <Grid item xs={12} sm={6} >
                      <TextField type="number" onChange={handleDiscountInput} label="Discount (%)"  size="small"  variant="outlined" className={classes.inputField} 
                      onKeyDown={(e)=>e.key==='Enter'?discountRef.current.focus():false}
                      value={pur_discount_percent}
                      name="pur_discount_percentage"
                      inputRef={discountRefPercentage} />
                      </Grid> 
                      <Grid item xs={0} sm={1}>
                      </Grid> 
                      <Grid item xs={12} sm={5}>
                      <TextField type="number" style={{marginRight:'5px'}}  size="small"  label="Discount(TK)" variant="outlined" className={classes.inputField} 
                      onKeyDown={(e)=>e.key==='Enter'?cgstRefPercent.current.focus():false}
                      value={pur_discount_amount}
                      onChange={handleDiscountInput}
                      name="pur_discount_amount"
                      inputRef={discountRef} /> 
                      </Grid>
                        
          </Grid>
          <Grid container>
                      <Grid item xs={12} sm={5} >
                      <TextField type="number" label="CGST (%)" variant="outlined"  size="small"   className={classes.inputField} 
                      value={pur_cgst_percent}
                      inputRef={cgstRefPercent} 
                      name="pur_cgst_percent"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?cgstRefAmount.current.focus():false} />
                      </Grid>
                      <Grid item xs={0} sm={1}>
                      </Grid>

                      <Grid item xs={12} sm={6} >
                      <TextField type="number" label="CGST" variant="outlined"  size="small"   className={classes.inputField} 
                      value={pur_cgst_amount}
                      inputRef={cgstRefAmount} 
                      name="pur_cgst_amount"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?sgstRefPercent.current.focus():false} />
                      </Grid>

          </Grid>
          <Grid container>
                      <Grid item xs={12} sm={5} >
                      <TextField type="number" label="SGST (%)" variant="outlined"  size="small"   className={classes.inputField} 
                      value={pur_sgst_percent}
                      inputRef={sgstRefPercent} 
                      name="pur_sgst_percent"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?sgstRefAmount.current.focus():false} />
                      </Grid>
                      <Grid item xs={0} sm={1}>
                      </Grid>

                      <Grid item xs={12} sm={6} >
                      <TextField type="number" label="SGST" variant="outlined"  size="small"   className={classes.inputField} 
                      value={pur_sgst_amount}
                      inputRef={sgstRefAmount} 
                      name="pur_sgst_amount"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?igstRefPercent.current.focus():false} />
                      </Grid>

          </Grid>
          <Grid container>
                      <Grid item xs={12} sm={5} >
                      <TextField type="number" label="IGST (%)" variant="outlined"  size="small"   className={classes.inputField} 
                      value={pur_igst_percent}
                      inputRef={igstRefPercent} 
                      name="pur_igst_percent"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?igstRefAmount.current.focus():false} />
                      </Grid>
                      <Grid item xs={0} sm={1}>
                      </Grid>

                      <Grid item xs={12} sm={6} >
                      <TextField type="number" label="IGST" variant="outlined"  size="small"   className={classes.inputField} 
                      value={pur_igst_amount}
                      inputRef={igstRefAmount} 
                      name="pur_igst_amount"
                      onChange={handleVatInput}
                      onKeyDown={(e)=>e.key==='Enter'?transportRef.current.focus():false} />
                      </Grid>

          </Grid>

          <Grid container style={{paddingRight:'1px'}}>
                      <Grid item xs={12} sm={5}>
                      <TextField type="number" style={{marginRight:'5px'}}  size="small"  label="Total" disabled variant="outlined" className={classes.inputField} 
                      value={purTotalAmo}
                    
                      name="purTotalAmo"
                      onKeyDown={(e)=>e.key==='Enter'?previousDueRef.current.focus():false}
                      inputRef={purTotalRef} />
                      </Grid>
                      <Grid item xs={0} sm={1}>
                      </Grid>

                      <Grid item xs={12} sm={6} >
                      <TextField type="text"  label="Rounded Off"  size="small"  variant="outlined" className={classes.inputField} 
                      onKeyDown={(e)=>e.key==='Enter'?dueRef.current.focus():false}
                      value={rounded_off}
                      onChange = {(e) => rounded_off_set(e.target.value)}
                      name="pur_transport_cost"
                      inputRef={transportRef} />
                      </Grid>    
                        
          </Grid>


          <Grid container style={{paddingRight:'1px'}}>
          {/* <Grid item xs={12} sm={5} >
                      <TextField type="number" label="Paid" variant="outlined"  size="small"  className={classes.inputField} 
                      value={purPaidAmo}
                      onChange={handlePaidInput}
                      name="purPaidAmo"
                      disabled={selectedSupplier!==null && selectedSupplier.supplier_id==0?true:false}
                      onKeyDown={(e)=>e.key==='Enter'?dueRef.current.focus():false}
                      inputRef={paidRef} />
                      </Grid>  */}

                      <Grid item xs={0} sm={1}>
                      </Grid>
                      <Grid item xs={12} sm={6} >
                      <TextField type="number" label="Due" variant="outlined"  size="small"  className={classes.inputField} 
                       value={purDueAmo}  
                       disabled
                       onChange={handleDueInput} 
                       onKeyDown={(e)=>e.key==='Enter'?savePurchaseRef.current.click():false}
                       name="purDueAmo"
                       inputRef={dueRef} /> 
                      </Grid>    


          </Grid>


      
          <Grid container>
{
                      purPaidAmo > 0 ?(
                        <Grid item xs={12} sm={5}  className={classes.plusLinkDiv} style={{marginTop:'15px',marginBottom:'10px'}}>
                        <Autocomplete
                        style={{ width: '100%' }}
                        options={supplierPayMethods}
                        size="small"
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight={true}
                        openOnFocus={true}
                        getOptionLabel={(option) => option.pay_method}
                        value={selectedSupplierPayMethod}  
                        onChange={(event,selectedObj)=>{
                 
                          selectedSupplierPayMethodSet(selectedObj) 

                          if(selectedObj != null && selectedObj.pay_method_id =='cash'){
                            selectedSupplierPayAccSet(null)
                          }
                          
                        }}
                        loading={supplierPayMethods.length==0?true:false}
                        renderInput={(params) => (
                            <TextField
                            //inputRef={customerPayMethodRef}
                            // onKeyDown={event => {
                            //     if (event.key === "Enter") {
                            //       customerPayAccRef.current.focus() 
                            //     }
                            //   }}
                            {...params}
                            label="Payment Method"
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {customerPayMethods.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment} */}
                                  </React.Fragment>
                                ),
                            }}
                            />
                        )}
                        /> 
                    </Grid>
                      ):''
                    }

<Grid item xs={12} sm={1}>

  </Grid>
{purPaidAmo > 0 ?(
    selectedSupplierPayMethod != null && selectedSupplierPayMethod.pay_method =='bank'?(
      <Grid item xs={12} sm={6}   className={classes.plusLinkDiv} style={{display:(selectedSupplierPayMethod!=null && selectedSupplierPayMethod.pay_method_id=='bank')?'block':'none',marginTop:'15px',marginBottom:'10px'}}>
      <Autocomplete
      style={{ width: '100%' }} 
      options={supplierPayAccs} 
      size="small"
      classes={{
          option: classes.option
      }}
      autoHighlight={true}
      openOnFocus={true}
      getOptionLabel={(option) =>option.bank_display_name}
      value={selectedSupplierPayAcc}  
      onChange={(event,selectedObj)=>{
        selectedSupplierPayAccSet(selectedObj)  
       
      }}
      loading={supplierPayAccs.length==0?true:false}
      renderInput={(params) => (
          <TextField
          //inputRef={customerPayAccRef}
          // onKeyDown={event => {
          //     if (event.key === "Enter") {
          //         customerPayMethodRef.current.focus() 
          //     }
          //   }}
          {...params}
          label="Bank account"
          variant="outlined"
          inputProps={{
              ...params.inputProps,
              endAdornment: (
                <React.Fragment>
                  {/* {customerPayAccs.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment} */}
                </React.Fragment>
              )
          }}
          />
      )}
      /> 
  </Grid>
    ):''
):''
  
} 


      
          {/* tds/tcs selection starts */}
          <Grid item xs={12} sm={3}> 
            <FormControlLabel
                control={
                  <Checkbox
                  
                    checked={is_tds}
                    onChange={(event)=>{
                      is_tds_set(event.target.checked);
                      
                    }}
                    name="checkedI"
                  />
                }
                label="TDS"
                style={{color: '#0f7e77'}}
            />
            </Grid>
            <Grid container style={{paddingRight:'1px'}}>
             {
                is_tds && selectedSupplier && (
                   <>
                       <Grid item xs={12} sm={5} >
                          <TextField type="number" label="TDS/TCS" variant="outlined"  size="small"  className={classes.inputField} 
                          disabled
                          value={selectedSupplier.supplier_tax_value} name="TDS/TCS"
                          /> 
                       </Grid> 
                       <Grid item xs={0} sm={1}>
                       </Grid>
                       <Grid item xs={12} sm={5} >
                          <TextField type="number" label="TDS/TCS Amt" variant="outlined"  size="small"  className={classes.inputField} 
                          disabled
                          value={parseFloat(parseFloat(selectedSupplier.supplier_tax_value)*parseFloat(purchaseData.pur_subtotal_amount)/100.0).toFixed(2)} name="TDS/TCS Amt"
                          /> 
                       </Grid>
                       <Grid item xs={0} sm={1}>
                       </Grid>
                       <Grid item xs={12} sm={5} >
                          <TextField type="number" label="Total" variant="outlined"  size="small"  className={classes.inputField} 
                          disabled
                          value={parseFloat(parseFloat(purchaseData.pur_subtotal_amount) - (parseFloat(selectedSupplier.supplier_tax_value)*parseFloat(purchaseData.pur_subtotal_amount)/100.0)).toFixed(2)} name="Total"
                          /> 
                       </Grid>  
                   </>
                )
             } 
            </Grid>
</Grid>

       <Grid>
       
				<input type="file" name="fileData" onChange={(e)=>setFileData(e.target.files[0])} />
        <CircularProgressWithLabel value={progress} />  
         
      </Grid>
        

          <Grid container style={{paddingRight:'1px'}}>
                      <Grid item xs={12} sm={6}>
                      <Button style={{marginTop: '5px',fontSize:'18px',float:'left'}} 
                            variant="contained"
                            color="primary"
                            size="small"
                            buttonRef={savePurchaseRef} 
                            onClick={savePurchase} 
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                            disabled={saveAction?true:false}
                        >
                       Purchase
                      </Button>
                      </Grid>
                  
                      <Grid item xs={12} sm={6} >
                      <Button 
                      style={{marginTop: '5px',fontSize:'10px',float:'right'}} 
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={()=>window.location.reload()}
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                        >
                       New Purchase
                      </Button>
                      </Grid>    
          </Grid>


          </Paper>
        </Grid>

        
      </Grid>
          </div>
      )
}


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(PurchaseEntry);